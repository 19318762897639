<script>
import ThemingMixin from "vue/applications/frontend/mixins/theming-mixin";

export default {
  mixins: [ThemingMixin],
  components: {
    Vnodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  props: {
    tabHeaders: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data: () => {
    return {
      currentSelection: null,
    };
  },
  computed: {
    selectedSlot() {
      if (this.currentSelection) {
        return this.$slots[this.currentSelection];
      } else {
        return this.$slots[Object.keys(this.$slots)[0]];
      }
    },
  },
  filters: {
    slotHeader(slotName, tabNames) {
      if (Object.keys(tabNames).indexOf(slotName) >= 0) {
        return tabNames[slotName];
      } else {
        return slotName;
      }
    },
  },
  methods: {
    select(tabName) {
      this.currentSelection = tabName;
    },
    slotCssClasses(slot, name, index) {
      const result = [];

      if (
        this.currentSelection === name ||
        (!this.currentSelection && index === 0)
      ) {
        result.push(this.themingCssClass("inverted"));
      } else {
        result.push(this.themingCssClass("normal"));
        result.push(this.themingCssClass("light_background"));
      }

      return result;
    },
  },
};
</script>

<template>
  <div class="components__tabbed-content">
    <div class="tabs">
      <div
        v-for="(slot, name, index) in $slots"
        v-bind:key="name"
        class="tab"
        v-bind:class="slotCssClasses(slot, name, index)"
        v-on:click="select(name)"
      >
        {{ name | slotHeader(tabHeaders) }}
      </div>
    </div>
    <div class="content">
      <vnodes v-bind:vnodes="selectedSlot" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~stylesheets/frontend/variables/colors";

.components__tabbed-content {
  .tabs {
    display: flex;

    .tab {
      padding: 8px 20px;
      margin-right: 16px;
      font-size: 16px;
      border-radius: 2px;
      cursor: pointer;
    }

    margin-bottom: 28px;
  }
}
</style>
