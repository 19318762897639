<script>
import HttpMethod from 'vue/applications/shared/endpoints/utils/http-method';
import UniversalEndpoint from '../../endpoints/universal-endpoint';
import CntComponent from '../../mixins/cnt-component'
export default {
  mixins: [CntComponent],

  data: () => {
    return {
      backendData: {
        selectedCurriculumId: null,
        selectedRoleId: null,
      },
      sending: false,
      errors: [],
    }
  },

  computed: {
    availableRolesForCurriculum: function() {
      return this
        .contentItem
        .widget_data
        .available_roles
        .filter(role => role.curriculum_id === Number(this.backendData.selectedCurriculumId));
    },

    selectedCurriculum: function() {
      if (
        !this.contentItem ||
        !this.contentItem.widget_data ||
        !this.contentItem.widget_data.available_curricula
      ) return null;

      return this.contentItem.widget_data.available_curricula.find(curriculum => curriculum.id === this.backendData.selectedCurriculumId);
    },

    selectedRole: function() {
      if (
        !this.contentItem ||
        !this.contentItem.widget_data ||
        !this.contentItem.widget_data.available_roles
      ) return null;

      return this.contentItem.widget_data.available_roles.find(role => role.id === this.backendData.selectedRoleId)
    }
  },

  methods: {
    onSelectTrialVersion: async function() {
      if (this.sending) return;

      try {
        this.sending = true;
        this.errors = [];

        const endpoint = new UniversalEndpoint(this.$store);
        // try to book trial version
        const response = await endpoint.request(
          this.contentItem.widget_data.to_trial_version_path,
          HttpMethod.POST,
          this.backendData,
        );

        if (response && response.data) {
          if (response.data.redirect) {
            // redirect user to dashboard
            location.href = response.data.redirect;
          } else {
            this.errors.push(response.data.error);
          }
        }
      } finally {
        this.sending = false;
      }
    }
  },

  watch: {
    "backendData.selectedCurriculumId": function() {
      this.backendData.selectedRoleId = null;
    },
    "contentItem.widget_data.available_curricula": {
      immediate: true,
      handler: function() {
        if (this.contentItem.widget_data.available_curricula.length == 1) {
          this.backendData.selectedCurriculumId = this.contentItem.widget_data.available_curricula[0].id;
        }
      }
    }
  }
}
</script>

<template>
  <div class="components__cnt-trial_version">
    <template v-if="contentItem">
      <div
        class="trial_version__not-logged-in"
        v-if="contentItem.widget_data.login_path"
      >
        Sie müssen sich zunächst
        <a
          v-bind:href="contentItem.widget_data.login_path"
        >
          einloggen oder registrieren
        </a>.
      </div>
      <div v-else>
        <div>
          <label class="trial_version__option">
            <template v-if="contentItem.widget_data.available_curricula.length > 1">
              <select v-model="backendData.selectedCurriculumId">
                <option v-bind:value="null">Bitte auswählen:</option>
                <option
                  v-for="curriculum in contentItem.widget_data.available_curricula"
                  v-bind:key="curriculum.id"
                  v-bind:value="curriculum.id"
                >
                  {{ curriculum.name }}
                </option>
              </select>
            </template>
            <div>
              <strong>
                <template v-if="selectedCurriculum">{{ selectedCurriculum.name }}</template>
                <template v-else>Curriculum</template>
              </strong>
              <div v-if="selectedCurriculum && selectedCurriculum.short_description">
                {{ selectedCurriculum.short_description }}
              </div>
            </div>
          </label>
        </div>

        <template v-if="backendData.selectedCurriculumId">
          <p v-if="availableRolesForCurriculum.length == 0">
            Aktuell steht für das Curriculum keine Schnupperversion zur Verfügung.
          </p>
          <div v-else>
            <label class="trial_version__option">
              <select v-model="backendData.selectedRoleId">
                <option v-bind:value="null">Bitte auswählen:</option>
                <option 
                  v-for="role in availableRolesForCurriculum"
                  v-bind:key="role.id"
                  v-bind:value="role.id"
                >
                  {{ role.label }}
                </option>
              </select>
              <div>
                <strong>
                  <template v-if="selectedRole">{{ selectedRole.label }}</template>
                  <template v-else>Ich möchte PaedCompenda nutzen als</template>
                </strong>
              </div>
            </label>
          </div>

          <template v-if="backendData.selectedRoleId">
            <div v-if="errors">
              <div
                v-for="(error, index) in errors"
                v-bind:key="index"
                class="booking__error"
              >
                {{ error }}
              </div>
            </div>
            <div>
              <button
                class="button"
                v-bind:class="{ 'button--disabled': sending }"
                v-on:click="onSelectTrialVersion"
              >
                <template v-if="sending">
                  Einen Moment bitte...
                </template>
                <template v-else>
                  Zur Schnupperversion
                </template>
              </button>
            </div>
          </template>
        </template>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.components__cnt-trial_version {
  @import "~stylesheets/frontend/variables/colors";

  .trial_version__not-logged-in {
    padding: 20px;
    background-color: $color-black-very-light;
  }

  .trial_version__option {
    display: block;
    padding: 20px;
    margin: 20px 0;
    border: 1px solid $color-green;

    display: flex;
    align-items: center;

    select {
      margin-right: 20px;
    }
  }

  .button {
    background-color: $color-green;
    color: $color-inverted;

    padding: 10px 20px;
    cursor: pointer;
    box-shadow: none;
    outline: none;
    border: none;
  }

  .booking__error {
    margin-bottom: 15px;
    color: red;
    font-weight: bold;
  }

  .button--disabled {
    background-color: $color-black-inactive;
    cursor: not-allowed;
  }
}
</style>
