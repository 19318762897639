<template>
  <div class="components__rec-diagnosis-selector">
    <p>
      Wählen Sie die Diagnose, für die die Beobachtung
      durchgeführt wurde. Es können auch mehrere Optionen gewählt werden.
    </p>
    <ul>
      <li v-for="diagnosis in description.diagnoses" v-bind:key="diagnosis.id">
        <label>
          <input type="checkbox" v-model="diagnosis.checked" v-bind:disabled="description.disabled" />
          {{ diagnosis.label }}
        </label>
        <input
          v-if="diagnosis.checked"
          type="hidden"
          v-bind:name="description.name"
          v-bind:value="diagnosis.id"
        />
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.components__rec-diagnosis-selector {
  p,
  ul,
  li {
    font-size: 16px;
  }

  ul {
    list-style: none;
    padding-left: 20px;
  }

  input[type="checkbox"] {
    margin-right: 5px;
  }
}
</style>

<script>
import RecComponent from "../../mixins/rec-component";

export default {
  mixins: [RecComponent],
};
</script>
