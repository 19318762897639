import ExamModule from "vue/applications/frontend/store/modules/exam";
import FilterModule from "vue/applications/frontend/store/modules/filter";
import GonModule from "vue/applications/frontend/store/modules/gon";

import VuexHooks from "vue/applications/frontend/store/hooks/all";
import RootState from "./frontend/store/root-state";

// Load all vue-components
const components: { [key: string]: any } = {};
const routes: { [key: string]: any } = {};
import ImportAll from "utils/import_all";
ImportAll(
  components,
  require.context("vue/applications/frontend/components/", true, /\.vue$/),
);

import { Store } from "vuex";

const FrontendApplication: VueApplication = {
  store(Vue, Vuex) {
    Vue.use(Vuex);
    const store = new Store<RootState>({
      modules: {
        exam: ExamModule,
        filter: FilterModule,
        gon: GonModule,
      },
    });

    // Run all the hooks that register once with the store
    for (const hook of VuexHooks) {
      hook(store);
    }

    return store;
  },
  initialize(Vue) {
    // Load all components, if the application is loaded (see initialize_vue_app)
    Object.keys(routes).forEach((key) => Vue.component(key, routes[key]));
    Object.keys(components).forEach((key) =>
      Vue.component(key, components[key]),
    );
  },
  // beforeMount(instanceConfiguration) {},
};

export default FrontendApplication;
