<template>
  <div class="components__progress-tile-bar">
    <div v-if="progress && progress.boxes" class="boxes">
      <div
        v-for="box in progress.boxes"
        class="box"
        v-bind:class="{inverted: box.inverted}"
        v-bind:title="boxTitle(box, progress.boxes)"
        v-bind:style="{'background-color': box.color, flex: flexValue(box, progress.boxes)}"
      >
        <template
          v-if="showBoxValue(box, progress.boxes)"
        >{{boxDisplayedValue(box, progress.boxes)}}</template>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~stylesheets/frontend/variables/colors";

.components__progress-tile-bar {
  .boxes {
    margin-top: 9px;

    display: flex;
    .box {
      height: 22px;
      text-align: center;
      color: $color-default;
      font-size: 14px;
      line-height: 22px;

      cursor: default;

      &.inverted {
        color: $color-white;
      }
    }
  }
}
</style>

<script>
export default {
  props: {
    progress: {
      type: Object,
      default: null,
    },
    displayAbsoluteValues: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    flexValue(box, boxes) {
      return Math.round((box.total / this.getTotal(boxes)) * 100) / 100;
    },
    showBoxValue(box, boxes) {
      return box.total / this.getTotal(boxes) > 0.1;
    },
    boxTitle(box, boxes) {
      return `${box.label}: ${this.boxDisplayedValue(box, boxes)}`;
    },
    boxDisplayedValue(box, boxes) {
      if (this.displayAbsoluteValues) {
        return box.total;
      }
      return Math.round((box.total * 100) / this.getTotal(boxes)) + "%";
    },
    getTotal(boxes) {
      let total = 0;
      if (boxes) {
        for (const box of boxes) {
          total += box.total;
        }
      }

      if (total === 0) {
        // mitigate division by zero
        return 1;
      }
      return total;
    },
  },
};
</script>
