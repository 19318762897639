<script>
import CntComponent from "../../mixins/cnt-component";

export default {
  mixins: [CntComponent],
  computed: {
    saneTiles() {
      return (this.contentItem.widget_data.jump_tiles || []).sort((a, b) => a.position - b.position);
    }
  }
};
</script>

<template>
  <div
    class="components__cnt-tiles"
  >
    <div class="jump-tiles">
      <div
        v-for="jumpTile in saneTiles"
        class="jump-tile"
        v-bind:class="[jumpTile.theming.border, jumpTile.theming.light_background]"
      >
        <div class="jump-tile-icon" v-bind:class="[jumpTile.theming.normal]">
          <i v-if="jumpTile.icon_name" v-bind:class="jumpTile.icon_name"></i>
        </div>
        <div class="jump-tile-description">
          <span v-html="jumpTile.description_html"></span>
        </div>
        <div class="jump-tile-button-spacer">
          {{ jumpTile.button_label }}
        </div>
        <simple-button class="jump-tile-button" filled v-bind:link-target="jumpTile.button_target" v-bind:use-incognito-theming="!!jumpTile.theming.use_theming" v-bind:class="[jumpTile.theming.inverted, jumpTile.theming.hover_box]">{{jumpTile.button_label}}</simple-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "~stylesheets/frontend/variables/colors";
@import "~include-media/dist/include-media";

$breakpoints: (
  "phone": 400px,
  "phone2": 500px,
  "tablet": 600px,
  "tablet2": 720px,
  "desktop": 1078px,
  "large": 1260px,
);

$height_breakpoint: 800px;
$reduced_height_padding: 35px;

// specificality 0 to not overwrite / interfere theming
:where(.jump-tile) {
  border: 1px solid $color-green;
  background-color: $color-green-hueish;
  box-shadow: 0 0 10px 0 transparentize($color-green, 0.7);
}
:where(.jump-tile-icon) {
  color: $color-green;
}

.jump-tiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  @include media("<phone2") {
    justify-content: space-around;
  }
  margin-bottom: 99px;

  @include media("height<=#{$height_breakpoint}") {
    margin-bottom: $reduced_height_padding;
  }

  .jump-tile {
    position: relative;

    $_jump-tile_padding: 15px;
    width: 180px;
    min-height: 220px;
    padding: $_jump-tile_padding;

    border-radius: 5px;
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;

    .jump-tile-icon {
      font-size: 45px;
      text-align: center;
      margin-bottom: 30px;
      margin-top: 10px;
    }
    .jump-tile-description {
      &,
      p {
        font-size: 16px;
        color: $color-default;
        text-align: center;
        line-height: 23px;
      }
    }
    .jump-tile-button-spacer {
      visibility: hidden;
      padding: 20px 40px;
    }
    .jump-tile-button {
      position: absolute;
      bottom: $_jump-tile_padding;
      left: $_jump-tile_padding;
      right: $_jump-tile_padding;
      font-size: 16px;
    }

    &::after {
      content: "";
      flex: auto;
    }
  }
}
</style>
