<!-- copied from rec-causes-selector.vue and slightly modified -->
<template>
  <div class="components__rec-checkboxes-selector">
    <p>
      {{ description.description_text }}
    </p>
    <div class="causes-container">
      <div
        class="causes-entry"
        v-for="score_item in description.checkboxes"
        v-bind:key="score_item.id"
      >
        <label>
          <div>
            <input
              type="checkbox"
              v-model="score_item.checked"
              v-bind:disabled="description.disabled"
            />
          </div>
          <div>{{ score_item.label }}</div>
        </label>
        <input
          v-if="score_item.checked || score_item.initially_checked"
          type="hidden"
          v-bind:name="score_item.name"
          v-bind:value="Number(score_item.checked)"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.components__rec-checkboxes-selector {
  p,
  div {
    font-size: 16px;
  }

  .causes-container {
    display: flex;
    flex-flow: row wrap;
  }

  .causes-entry {
    margin-right: 15px;
    width: 350px;
    margin-bottom: 15px;

    label {
      display: flex;
    }
  }

  input[type="checkbox"] {
    margin-right: 5px;
  }
}
</style>

<script>
import RecComponent from "../../mixins/rec-component";

export default {
  mixins: [RecComponent],
};
</script>
