import { GetterTree } from "vuex";

import RootState from "../../root-state";
import State from "./state";

// GetterTree<[current state], [root state]>
const getters: GetterTree<State, RootState> = {
  themingCssClass: (state) => (selectorName) => {
    const currentTheme = state?.content?.current_theme || state?.content_page?.current_theme;

    if (!currentTheme) return "";
    return (
      currentTheme[selectorName] ||
      currentTheme.default
    ).class_name;
  },
};

export default getters;
