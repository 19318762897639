<template>
  <select class="components-wrapper__select2">
    <slot></slot>
  </select>
</template>

<style src="select2/dist/css/select2.css"></style>

<script>
import $ from "jquery";
import "wrapper/select2";

export default {
  mounted() {
    $(this.$el).select2();
  },
  destroyed: function () {
    $(this.$el).off().select2("destroy");
  },
};
</script>
