<template>
  <div class="components__render-content-page" v-bind:style="styleObject">
    <div
      class="render-widget"
      v-for="contentItem in contentItems"
      v-bind:key="contentItem.position"
    >
      <template v-if="contentItem.widget_config.backend">
        <slot v-bind:name="`slot-${contentItem.id}`"><pre>{{contentItem}}</pre></slot>
      </template>
      <component v-else="contentItem.widget_config.backend" v-bind:is="contentItem.widget_type" v-bind:content-item="contentItem" />
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    contentPage() {
      return this.$store.state.gon.content_page;
    },
    contentItems() {
      return ((this.contentPage || {}).content_items || []).sort(
        (a, b) => a.position - b.position,
      );
    },
    styleObject() {
      const styles = {};

      if (this.contentPage.page_width)
        styles["max-width"] = this.contentPage.page_width + "px";

      if (this.contentPage.plain_style) styles["margin"] = "auto";

      return styles;
    },
  },
};
</script>
