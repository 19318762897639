<script>
import ThemingMixin from "vue/applications/frontend/mixins/theming-mixin";

export default {
  mixins: [ThemingMixin],
  computed: {
    content() {
      return this.$store.state.gon.content;
    },
    // request methods other than GET or POST are not supported by all browsers and therefore simulated via a POST request
    // returns the request method which is actually used by the browser (i.e. GET or POST)
    actualRequestMethod() {
      if (!this.content.form_options) return undefined;
      if ([ 'get', 'post' ].includes(this.content.form_options.method)) return this.content.form_options.method;
      return 'post';
    },
    // returns whether the desired request method is simulated by a POST request
    // if it is the case, a hidden _method input field is generated as part of the form
    isOtherRequestMethodSimulated() {
      return this.actualRequestMethod != (this.content.form_options || {}).method;
    }
  },
  methods: {
    submit() {
      if (this.content.as_form) {
        // previously, this.$refs.form.requestSubmit() was used
        // however, unfortunately it is not supported on Safari < 16
        if (this.$refs.form.reportValidity()) {
          this.$refs.form.submit();
        }
      } else {
        console.error("Not rendered as a form. This should not happen.");
      }
    },
    submitAsDraft() {
      // mark exam as draft, then submit normally
      this.$refs.form['exam[is_draft]'].value = true;
      this.submit();
    }
  },
};
</script>

<template>
  <div class="components__exam-sheet">
    <div class="content">
      <div class="sheet">
        <div
          v-if="content && content.title"
          class="g--broad_heading print--no-shadow print--black-and-white"
          v-bind:class="[themingCssClass('inverted')]"
        >
          {{ content.title }}
          <back-button />
        </div>
        <form
          v-bind:is="content.as_form ? 'form' : 'div'"
          ref="form"
          v-bind:action="(content.form_options || {}).action"
          v-bind:method="actualRequestMethod"
        >
          <template v-if="isOtherRequestMethodSimulated">
            <input name="_method" type="hidden" v-bind:value="content.form_options.method">
          </template>
          <template v-if="content.as_form">
            <input
              v-for="(value, name) in content.form_options.data"
              v-bind:key="name"
              type="hidden"
              v-bind:name="name"
              v-bind:value="value"
            />
          </template>
          <template v-for="card in content.cards">
            <div class="card print--no-shadow print--no-padding" v-bind:key="card.key" v-bind:class="{'print--hide': card.print_hide}">
              <div
                v-if="card.title"
                class="title"
                v-bind:class="[themingCssClass('normal')]"
              >
                {{ card.title }}
              </div>
              <component
                v-bind:is="card.component"
                v-bind:description="card"
                v-on:submit="submit"
                v-on:submit-as-draft="submitAsDraft"
              />
            </div>
          </template>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "~stylesheets/frontend/variables/colors";

.components__exam-sheet {
  .sheet {
    .card {
      background-color: colors.$color-white;
      box-shadow: 0 0 15px 0 rgba(3, 3, 3, 0.1);
      color: colors.$color-default;
      padding: (66px - 18px)/2;
      margin-bottom: 23px;

      .title {
        font-size: 18px;
        margin-bottom: 15px;
      }

      &.filter {
      }

      &.score-items {
        .score-item {
        }

        .button-bar {
          height: 50px;
          .button-next {
            margin-top: 15px;
            float: right;

            .button-next-a {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
</style>
