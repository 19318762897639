export default {
  props: {
    contentItem: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
