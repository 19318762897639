import { MutationTree } from "vuex";

import MutationType from "./mutation-type";
import State from "./state";

const mutations: MutationTree<State> = {
  [MutationType.updateCurrentAreaKey](
    state: State,
    newAreaKey: number | string,
  ) {
    state.currentAreaKey = newAreaKey;
  },
};

export default mutations;
