<template>
  <div class="components__rec-date-field">
    <div
      v-if="description.readonly"
    >{{ description.label }}: {{ description.ivalue | formatISODate }}</div>
    <simple-datepicker
      v-else
      v-bind:name="description.name"
      v-model="description.ivalue"
      small
    >{{ description.label }}</simple-datepicker>
  </div>
</template>

<script>
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";

import RecComponent from "../../mixins/rec-component";

export default {
  mixins: [RecComponent],
  filters: {
    formatISODate(strDate) {
      return format(parseISO(strDate), "dd.MM.yyyy");
    },
  },
};
</script>
