<template>
  <div class="components__rec-filter-bar">
    <div v-for="filter in filters" v-bind:key="filter.key" class="filter-entry">
      <label>
        <input type="checkbox" v-model="filter.checked" />
        <template v-if="typeof filter.value == 'number'"
          >{{ filter.value }} ({{ filter.label }})</template
        >
        <template v-else>{{ filter.label }}</template>
      </label>
    </div>
    <div
      class="filter-remove"
      v-bind:class="[themingCssClass('normal')]"
      v-on:click="reset"
    >
      <i class="frontend-icon fi-close"></i>
      <span>Filter zurücksetzen</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.components__rec-filter-bar {
  display: flex;

  .filter-entry {
    margin-right: 24px;

    input {
      margin-right: 8px;
      vertical-align: middle;
    }
    label {
      font-size: 16px;
    }
  }

  .filter-remove {
    margin-left: auto;
    margin-right: 36px - 18px;
    font-size: 14px;
    cursor: pointer;

    .frontend-icon {
      font-size: 25px;
      margin-right: 11px;
    }

    span {
      vertical-align: 2.5;
    }
  }
}
</style>

<script>
import RecComponent from "../../mixins/rec-component";

export default {
  mixins: [RecComponent],
  computed: {
    filters() {
      return this.$store.state.filter.definitions;
    },
  },
  methods: {
    reset() {
      this.$store.commit("resetFilter");
    },
  },
};
</script>
