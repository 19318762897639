import { GetterTree } from "vuex";

import RootState from "../../root-state";
import State from "./state";

interface IDToChecked {
  [id: number]: boolean;
}

// GetterTree<[current state], [root state]>
const getters: GetterTree<State, RootState> = {
  filterIDToChecked(state: State): IDToChecked {
    const result: IDToChecked = {};
    for (const definition of (state as any).definitions || []) {
      result[definition.key as number] = !!definition.checked;
    }
    return result;
  },
  filteringActive(state: State): boolean {
    return !(state as any).inactive;
  },
};

export default getters;
