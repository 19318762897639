<template>
  <div class="components__cnt-accordion" v-bind:style="{'margin-left': contentItem.widget_data.margin_left}">
    <template v-if="contentItem">
      <generic-accordion>
        <template v-slot:header>
          <h2 v-bind:class="[themingCssClass('normal_important')]">
            {{ contentItem.widget_data.title }}
          </h2>
        </template>
        <template v-slot:content>
          <div
            v-for="childItem in contentItem.child_content_items"
            v-bind:key="childItem.id"
          >
            <component
              v-bind:is="childItem.widget_type"
              v-bind:content-item="childItem"
            />
          </div>
        </template>
      </generic-accordion>
    </template>
  </div>
</template>

<script>
import CntComponent from "../../mixins/cnt-component";
import ThemingMixin from "vue/applications/frontend/mixins/theming-mixin";

export default {
  mixins: [CntComponent, ThemingMixin],
};
</script>
