<script>
export default {
  props: {
    placeholder: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: null,
    },
    password: {
      type: Boolean,
      default: false,
    },
    value: {
      // v-model
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: null,
    },
    small: {
      type: Boolean,
      default: false,
    },
    verySmall: {
      type: Boolean,
      default: false
    },
    forceType: {
      type: String,
      default: null,
    },
    maxLength: {
      type: Number,
      default: null,
    }
  },
  computed: {
    type() {
      if (this.forceType) {
        return this.forceType;
      } else if (this.password) {
        return "password";
      } else {
        return "text";
      }
    },
  },
};
</script>

<template>
  <div class="components__simple-textfield">
    <div class="label-container">
      <label v-bind:class="{ small, 'very-small': verySmall }">
        <slot></slot>
      </label>
    </div>
    <div class="input-container">
      <input
        v-bind:name="name"
        v-bind:value="value"
        v-on:input="$emit('input', $event.target.value)"
        v-bind:type="type"
        v-bind:placeholder="placeholder"
        v-bind:class="{ small, 'very-small': verySmall }"
        v-bind:maxlength="maxLength"
      />
      <template v-if="!!icon">
        <i class="frontend-icon" v-bind:class="icon"></i>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~stylesheets/frontend/variables/colors";

.components__simple-textfield {
  .label-container {
    margin-bottom: 3px;
  }

  label {
    width: 100%;

    color: $color-default;
    font-size: 18px;

    &.very-small {
      font-size: 14px;
    }
  }

  .input-container {
    position: relative;

    i {
      position: absolute;
      right: 14px;
      top: 20px;
      font-size: 20px;
      color: $color-black-placeholder;
    }
  }

  input {
    width: 100%;
    height: 60px;
    border: 1px solid $color-black-border;
    border-radius: 2px;
    background-color: $color-white;
    box-shadow: none;
    padding-left: 14px;

    &.small {
      height: 30px;
    }

    &.very-small {
      height: 25px;
      width: initial;
    }

    &::placeholder {
      color: $color-black-placeholder;
      font-size: 18px;
    }

    &:focus {
      border: 1px solid $color-green;
      box-shadow: none;
      box-shadow: 0 0 10px 0 transparentize($color-green, 0.7);
      outline: none;
    }
  }
}
</style>
