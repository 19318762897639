/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Adding IE11 compatibility
import "core-js/stable";
import "regenerator-runtime/runtime";

// Load Rails stuff
import Rails from "@rails/ujs";
import "channels";
Rails.start();

// Load normalization
import "normalize.css";

// Load stylesheet
import "typeface-roboto-condensed/index.css"; // Load roboto
import "./stylesheets/frontend.scss";

// Load webfonts
import "./webfonts/collections/frontend-icons.font.js";

// // Load everything from './helper/'
import ImportAll from "./utils/import_all";
ImportAll('helper', require.context('./helper/', true, /\.js$|(^.?|\.[^d]|[^.]d|[^.][^d])\.ts$/));

// Load vue application
import "./vue/applications/loader/frontend-loader";

// Load images
import "./images/logo_paedcompenda.png";
import "./images/logo_paedcompenda_inverted.png";
import "./images/paedcompenda_flower.png";
import "./images/logo_dgaap.png";
import "./images/logo_bvkj.png";
import "./images/logo_bvkj-service.png";

// Load checked state of checkbox
import "./images/checkbox_checked.png";

