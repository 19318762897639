<template>
  <div ref="root" class="components__dropdown-menu">
    <slot />
    <div class="dd-arrow">
      <i v-if="open" class="frontend-icon fi-expand_less"></i>
      <i v-else class="frontend-icon fi-expand_more"></i>
    </div>
    <div ref="submenu" class="dd-submenu">
      <slot name="submenu" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~stylesheets/frontend/variables/colors";

.components__dropdown-menu {
  cursor: pointer;
  display: flex;
}

// style outside because tether moves it around
.dd-submenu {
  padding: 20px;
  background-color: $color-white;
}
</style>

<script>
import Drop from "tether-drop";

export default {
  props: {
    position: {
      type: String,
      default: "bottom center",
    },
    additionalClasses: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dropInstance: null,
      open: false,
    };
  },
  mounted() {
    this.ensureDropDestroy();
    this.dropInstance = new Drop({
      target: this.$refs["root"],
      content: this.$refs["submenu"],
      position: this.position,
      openOn: "click",
      remove: true,
      classes: this.additionalClasses,
    });
    this.dropInstance.on("open", () => {
      this.open = true;
    });
    this.dropInstance.on("close", () => {
      this.open = false;
    });
  },
  beforeDestroy() {
    this.ensureDropDestroy();
  },
  methods: {
    ensureDropDestroy() {
      if (this.dropInstance) {
        this.dropInstance.destroy();
        this.dropInstance = null;
      }
    },
  },
};
</script>
