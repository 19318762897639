<template>
  <div ref="scroll" class="components__competency-accordion">
    <generic-accordion v-if="competency" v-model="competency.expanded">
      <template v-slot:header>
        <h2>{{competency.abbreviation}} - {{competency.name}}</h2>
      </template>
      <template v-slot:content>
        <template v-if="competency.descriptions.length > 0">
          <ul>
            <li
              v-for="(description, index) in competency.descriptions"
              v-bind:key="index"
            >{{description}}</li>
          </ul>
        </template>
        <div
          class="sub-competency"
          v-bind:class="{highlight: sub_competency.highlighted}"
          v-for="sub_competency in competency.sub_competencies"
          v-bind:key="sub_competency.id"
        >
          <div class="sub-competency__abbreviation">{{sub_competency.abbreviation}}:</div>
          <div>{{sub_competency.description}}</div>
        </div>
      </template>
    </generic-accordion>
  </div>
</template>

<style lang="scss" scoped>
@import "~stylesheets/frontend/variables/colors";

.components__competency-accordion {
  .sub-competency {
    display: flex;
    padding: 5px;

    &.highlight {
      background-color: $color-green-hueish;
    }

    .sub-competency__abbreviation {
      margin-right: 10px;
    }
  }
}
</style>

<script>
export default {
  props: {
    competency: {
      type: Object,
      default: null,
    },
  },
  watch: {
    "competency.jump_target": {
      handler(newValue) {
        if (this.competency.jump_target) {
          this.$nextTick().then(() => {
            const element = this.$refs.scroll;
            // This is a hack because directly executing wont work
            setTimeout(() => {
              element.scrollIntoView();
              this.competency.jump_target = false;
            }, 200);
          });
        }
      },
      immediate: true,
    },
  },
};
</script>
