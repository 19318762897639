<template>
  <div class="components__rec-causes-selector">
    <p>
      {{ description.explanation_text }}
    </p>
    <div class="causes-container">
      <div
        class="causes-entry"
        v-for="subapt in description.causes"
        v-bind:key="subapt.id"
      >
        <label>
          <div>
            <input
              type="checkbox"
              v-model="subapt.checked"
              v-bind:disabled="description.disabled"
            />
          </div>
          <div>{{ subapt.label }}</div>
        </label>
        <input
          v-if="subapt.checked"
          type="hidden"
          v-bind:name="description.name"
          v-bind:value="subapt.id"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.components__rec-causes-selector {
  p,
  div {
    font-size: 16px;
  }

  .causes-container {
    display: flex;
    flex-flow: row wrap;
  }

  .causes-entry {
    margin-right: 15px;
    width: 350px;
    margin-bottom: 15px;

    label {
      display: flex;
    }
  }

  input[type="checkbox"] {
    margin-right: 5px;
  }
}
</style>

<script>
import RecComponent from "../../mixins/rec-component";

export default {
  mixins: [RecComponent],
};
</script>
