<template>
  <div class="components__gon-wrapper">
    <slot v-bind:gon="gon"></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      gon: JSON.parse(JSON.stringify(window.gon)),
    };
  },
};
</script>
