<script>
import RecComponent from "../../mixins/rec-component";

export default {
  mixins: [RecComponent],
  data() {
    return {
      currentAreaPointer: 0,
    };
  },
  mounted() {
    this.$store.commit("updateCurrentAreaKey", this.description.areas[0].key);
  },
  computed: {
    activeAreaKey() {
      return this.$store.state.exam.currentAreaKey;
    },
    activeAreaIndex() {
      let index = 0;
      for (const area of this.description.areas) {
        if (area.key === this.activeAreaKey) {
          return index;
        }
        index += 1;
      }
    },
    isLast() {
      return this.activeAreaIndex + 1 == this.description.areas.length;
    },
    isFirst() {
      return this.activeAreaIndex == 0;
    },
  },
  methods: {
    next() {
      const nextIndex =
        (this.activeAreaIndex + 1) % this.description.areas.length;
      this.$store.commit(
        "updateCurrentAreaKey",
        this.description.areas[nextIndex].key,
      );
    },
    prev() {
      const prevIndex =
        (this.description.areas.length + this.activeAreaIndex - 1) %
        this.description.areas.length;
      this.$store.commit(
        "updateCurrentAreaKey",
        this.description.areas[prevIndex].key,
      );
    },
    submit() {
      if (!this.description.readonly) {
        this.$emit("submit");
      } else {
        console.error("Submitting is not allowed for a readonly form");
      }
    },
    submitAsDraft() {
      if (!this.description.readonly) {
        this.$emit("submit-as-draft");
      } else {
        console.error("Submitting as draft is not allowed for a readonly form");
      }
    },
  },
};
</script>

<template>
  <div class="components__progress-area">
    <div
      v-for="area in description.areas"
      v-bind:key="area.key"
      class="progress-single-area print--show"
      v-bind:class="{ inactive: area.key !== activeAreaKey }"
    >
      <div
        class="area-header print--avoid-break-page-after"
        v-bind:class="[themingCssClass('normal')]"
        v-if="area.area_title || area.area_subtitle"
      >
        <div class="area-title" v-if="area.area_title">
          {{ area.area_title }}
        </div>
        <div class="area-subtitle" v-if="area.area_subtitle">
          {{ area.area_subtitle }}
        </div>
      </div>
      <component
        v-bind:is="area.component"
        v-bind:description="area"
        v-on:submit="$emit('submit')"
      />
    </div>
    <div class="button-container print--hide">
      <div class="button-prev">
        <simple-button
          use-theming
          v-if="!isFirst"
          class="button-a"
          v-on:main-action="prev"
          >Zurück</simple-button
        >
      </div>
      <div class="button-next print--hide">
        <template v-if="isLast && !description.readonly">
          <div class="button-container--inner">
            <template v-if="description.exam_is_draft">
              <simple-button
                use-theming
                class="button-a"
                v-on:main-action="submitAsDraft"
              >
                Als Entwurf speichern
              </simple-button>
            </template>

            <simple-button
              use-theming
              class="button-a"
              v-on:main-action="submit"
              filled
              >Speichern</simple-button
            >
          </div>
        </template>
        <template v-else-if="!isLast">
          <simple-button use-theming class="button-a" v-on:main-action="next"
            >Weiter</simple-button
          >
        </template>
      </div>
    </div>
    <div v-if="isLast && !description.readonly && description.exam_is_draft" class="save-as-draft-explanation">
      Wenn Sie den Bogen als Entwurf speichern, ist er zunächst nur für Sie sichtbar. Sie können den Bogen nach dem Speichern jederzeit bearbeiten.
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~stylesheets/frontend/variables/colors";

.components__progress-area {
  .progress-single-area {
    &.inactive {
      display: none;
    }

    @media print {
      margin-top: 15px;
    }
  }
  .area-header {
    font-size: 18px;
    margin-bottom: 15px;

    .area-subtitle {
      margin-top: 6px;
      font-size: 16px;
      color: $color-default;
    }
  }
  .button-container {
    display: flex;
    margin-top: 15px;

    .button-prev {
    }

    .button-next {
      margin-left: auto;

      .button-container--inner {
        display: flex;
      }
    }

    .button-a {
      font-size: 14px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    .button-a--small {
      font-size: 12px;
      padding-top: 3px;
      padding-bottom: 2px;

      i {
        font-size: 16px;
      }
    }

    .print-button {
      margin-top: 15px;
    }

    .button-content {
      display: flex;
      align-items: center;
    }

    .button-label {
      margin-left: 5px;
      line-height: 1;
    }
  }

  .save-as-draft-explanation {
    margin-top: 20px;
  }
}
</style>
