<script>
import RecComponent from '../../mixins/rec-component'
export default {
  mixins: [RecComponent],
  methods: {
    print() {
      var textareas = document.querySelectorAll('textarea');
      textareas.forEach(function(textarea) {
        var textareaContent = document.createElement('p');
        textareaContent.className = "tmp--print"
        textareaContent.style.border = "solid grey 1px"
        textareaContent.style.padding = "10px"
        textareaContent.style.margin = "15px"
        textareaContent.style.minHeight = "40px"
        textareaContent.textContent = textarea.value;
        textarea.after(textareaContent);
        textarea.style.display = "none"
      });

      // we use custom appearance of checkboxes
      // since this not displayed correctly when printing, use the standard appearance
      var checkboxes = document.querySelectorAll('input[type="checkbox"]');
      checkboxes.forEach(function(checkbox) {
        checkbox.style.appearance = "auto";
      });

      // when printing black-white, the difference between selected and unselected bubbles is very hard to recognize
      // => underline the selected bubble
      var selectedBubbles = document.querySelectorAll('.bubble-is-selected');
      selectedBubbles.forEach(function(bubble) {
        bubble.style.textDecoration = 'underline 2px';
      });

      window.print();

      // undo changes
      document.querySelectorAll('.tmp--print').forEach(e => e.remove());
      textareas.forEach(function(textarea) {
        textarea.style.display = "block"
      });

      checkboxes.forEach(function(checkbox) {
        checkbox.style.appearance = "none";
      });

      selectedBubbles.forEach(function(bubble) {
        bubble.style.textDecoration = "none";
      })
    },
  }
}
</script>

<template>
  <div class="components__print-button">
    <div class="button-container print--hide">
      <simple-button filled class="button-a button-a--small print-button" use-theming v-on:main-action="print"
        ><div class="button-content"><i class="frontend-icon fi-printer"></i> <span class="button-label">Drucken</span></div></simple-button
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~stylesheets/frontend/variables/colors";

.components__print-button {
  .button-container {
    display: flex;
    margin-top: 15px;

    .button-a {
      font-size: 14px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    .button-a--small {
      font-size: 12px;
      padding-top: 3px;
      padding-bottom: 2px;

      i {
        font-size: 16px;
      }
    }

    .button-content {
      display: flex;
      align-items: center;
    }

    .button-label {
      margin-left: 5px;
      line-height: 1;
    }
  }
}

</style>
