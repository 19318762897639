<script>
import $ from "jquery";
import ThemingMixin from "vue/applications/frontend/mixins/theming-mixin";

export default {
  mixins: [ThemingMixin],
  props: {
    content: String,
    inverted: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    linkTarget: {
      type: String,
      default: null,
    },
    linkMethod: {
      type: String,
      default: null,
    },
    submitForm: {
      type: Boolean,
      default: false,
    },
    useTheming: {
      type: Boolean,
      default: false,
    },
    useIncognitoTheming: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    buttonClick() {
      if (this.submitForm) {
        $(this.$refs["root"]).parents("form").submit();
      } else {
        if (
          this.linkTarget &&
          (!this.linkMethod || this.linkMethod.toLowerCase() === "get")
        ) {
          window.location.href = this.linkTarget;
        } else if (this.linkTarget && this.linkMethod && this.linkMethod) {
          // Get CSRF-Token
          const metas = document.getElementsByTagName("meta");
          let token = null;
          for (let i = 0; i < metas.length; i++) {
            if (metas[i].getAttribute("name") === "csrf-token") {
              token = metas[i].getAttribute("content");
              break;
            }
          }

          // Create an setup form
          const form = document.createElement("form");
          form.action = this.linkTarget;
          form.method = "post";
          form.style = "display: none;";

          // Smuggle in the method (because forms only can post)
          // so that rails understands we are doing a emulated "this.linkMethod"-call
          let input = document.createElement("input");
          input.type = "hidden";
          input.name = "_method";
          input.value = this.linkMethod.toLowerCase();
          form.appendChild(input);

          // Append authenticity token for CSRF protection
          input = document.createElement("input");
          input.type = "hidden";
          input.name = "authenticity_token";
          input.value = token;
          form.appendChild(input);

          // Apend and send form
          document.body.appendChild(form);
          form.submit();
        } else {
          this.$emit("main-action");
        }
      }
    },
    cssClasses() {
      const result = [];

      // { inverted, filled, unthemed: !useTheming }

      if (this.inverted) {
        result.push("inverted");
      } else if (this.filled) {
        result.push("filled");
      }

      if (this.useTheming) {
        if (this.inverted) {
          result.push(this.themingCssClass("hover_main_color"));
        } else if (this.filled) {
          result.push(this.themingCssClass("border"));
          result.push(this.themingCssClass("inverted"));
          result.push(this.themingCssClass("hover_box"));
        } else {
          result.push(this.themingCssClass("border"));
          result.push(this.themingCssClass("normal"));
          result.push(this.themingCssClass("hover_invert"));
        }
      } else if(!this.useIncognitoTheming) {
        result.push("theming-not-used");
      }

      return result;
    },
  },
};
</script>

<template>
  <div
    ref="root"
    v-on:click="buttonClick"
    class="components__simple-button center"
    v-bind:class="cssClasses()"
  >
    <div class="center">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "~stylesheets/frontend/variables/colors";

.components__simple-button {
  &.theming-not-used {
    color: colors.$color-green;
    border: 1px solid colors.$color-green;

    &:hover,
    &.filled {
      background-color: colors.$color-green;
      color: colors.$color-inverted;
    }

    &.filled {
      &:hover {
        box-shadow: 0 0 10px 0 transparentize(colors.$color-green, 0.2);
      }
    }

    &.inverted {
      &:hover {
        color: colors.$color-green;
      }
    }
  }
}

.components__simple-button {
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding: 9px 30px;

  font-size: 18px;

  border-radius: 2px;

  cursor: pointer;
  user-select: none;

  &.center {
    text-align: center;
  }

  &.inverted {
    border: 1px solid colors.$color-inverted;
    color: colors.$color-inverted;

    &:hover {
      background-color: colors.$color-inverted;
    }
  }
}
</style>
