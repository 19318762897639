// interface Gon {
//   csrf_token: string;
//   last_request: string;
//   logged_in: boolean;
//   session_duration: number;
//   counter: number;
// }

declare var gon: any;

export default class FilterState {
  constructor() {
    Object.assign(
      this,
      JSON.parse(JSON.stringify(((gon || {}).content || {}).filter || {})),
    );
  }
}
