<script>
import RecComponent from "../../mixins/rec-component";

export default {
  mixins: [RecComponent],
};
</script>

<template>
  <div
    class="components__rec-score-category"
    v-bind:class="{ 'score-category--with-legend': !!description.legend }"
  >
    <div
      class="score-category-legend"
      v-if="description.legend"
      v-bind:class="[themingCssClass('normal')]"
    >
      {{ description.legend }}
    </div>
    <score-item
      class="score-item"
      v-for="item in description.items"
      v-bind:key="item.id"
      v-bind:score-item="item"
    ></score-item>
  </div>
</template>

<style lang="scss" scoped>
.components__rec-score-category {
  position: relative;
  &.score-category--with-legend {
    margin-top: 30px;
  }

  .score-category-legend {
    position: absolute;
    top: -25px;
    right: 0;
    text-align: right;
    font-size: 14px;
    margin-bottom: 5px;
  }
}
</style>
