<script>
import SyncedSlots from "../../mixins/synced-slots";

export default {
  mixins: [
    SyncedSlots,
  ],
};
</script>

<template>
  <div class="layout layout--left-right">
    <div class="columns">
      <div><slot name="label"></slot></div>
      <div>
        <div class="scores user-input"><slot name="user-input"></slot></div>
        <div class="scores previous-input print--hide"><slot name="previous-input"></slot></div>
        <div v-for="syncedSlot in syncedSlotNames" class="scores synced-input print--hide"><slot v-bind:name=syncedSlot></slot></div>
      </div>
    </div>
    <div><slot name="keywords"></slot></div>
    <div><slot name="more-button"></slot></div>
  </div>
</template>

<style lang="scss" scoped>
@import "~include-media/dist/include-media";

$breakpoints: (
  "tablet": 1000px,
);

.columns {
  @include media(">=tablet") {
    display: flex;
    justify-content: space-between;
  }
}

.scores {
  display: flex;
}

</style>
