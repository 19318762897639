<script>
import RecComponent from "../../mixins/rec-component";

export default {
  mixins: [RecComponent],
  data() {
    return {
      visitedKeys: [],
    };
  },
  methods: {
    select(newKey) {
      this.$store.commit("updateCurrentAreaKey", newKey);
    },
    bubbleCssClasses(step) {
      const result = [];

      result.push(this.themingCssClass("border"));

      if (step.key === this.currentAreaKey) {
        result.push("active");
        result.push(this.themingCssClass("inverted"));
      } else {
        result.push(this.themingCssClass("normal"));
      }
      if (this.visitedKeys.indexOf(step.key) >= 0) result.push("visited");

      return result;
    },
  },
  computed: {
    currentAreaKey() {
      return this.$store.state.exam.currentAreaKey;
    },
    moreThanOneElement() {
      return ((this.description || {}).steps || []).length > 1;
    },
  },
  watch: {
    currentAreaKey: {
      immediate: true,
      handler(newVal) {
        if (this.visitedKeys.indexOf(newVal) < 0) {
          this.visitedKeys.push(newVal);
        }
      },
    },
  },
};
</script>

<template>
  <div class="components__rec-progress-bar">
    <div v-if="moreThanOneElement" class="bubbles">
      <div
        class="bubble"
        v-for="step in description.steps"
        v-bind:key="step.key"
        v-bind:class="bubbleCssClasses(step)"
        v-on:click="select(step.key)"
      >
        <div class="bubble-label">{{ step.label }}</div>
        <div class="bubble-text">{{ step.text }}</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.components__rec-progress-bar {
  $_bubble-margin: 8px;

  position: relative;
  margin-bottom: 20px - $_bubble-margin;

  .bubbles {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    .bubble {
      border-radius: 5px;
      font-size: 14px;

      height: 30px;
      min-width: 30px;
      max-width: 50px;
      text-align: center;
      line-height: 30px;
      margin-right: $_bubble-margin;
      margin-bottom: $_bubble-margin;

      display: flex;
      justify-content: center;

      cursor: pointer;

      $_animation_max_width: 700px;
      $_animation_duration: 0.75s;
      $_animation_function: linear;

      .bubble-text {
        max-width: 0;
        overflow: hidden;
        transition: all $_animation_duration $_animation_function;
      }

      .bubble-label {
        padding-left: 10px;
        padding-right: 10px;
      }

      transition: max-width $_animation_duration $_animation_function;
      &:hover,
      &.active {
        max-width: $_animation_max_width;
        transition: max-width $_animation_duration $_animation_function;
        text-align: left;

        .bubble-text {
          max-width: $_animation_max_width;
          transition: max-width $_animation_duration $_animation_function;
          margin-right: 15px;
        }
      }
    }
  }
}
</style>
