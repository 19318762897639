<template>
  <div
    class="components__cnt-slide_show"
    v-on:contextmenu.prevent
    tabindex="-1"
    v-on:keydown.right="navigate(true)"
    v-on:keydown.left="navigate(false)"
    v-on:wheel.prevent="onWheel"
  >
    <div class="not-authorized" v-if="notAuthorized">
      <i class="frontend-icon fi-fa_lock"></i>
      <i>Auf diesen Inhalt haben Sie keinen Zugriff</i>
    </div>
    <template v-else>
      <div class="slide__navigation">
        <div class="slide__navigation_section">
          <a v-on:click="navigate(false)">Zurück</a
          ><a v-on:click="navigate(true)">Weiter</a>
        </div>
        <div
          class="slide__navigation_section slide__navigation_section--center"
        >
          {{ currentSlidePosition }}
          / {{ totalSlides }}
        </div>
        <div class="slide__navigation_section slide__navigation_section--right">
          <input
            placeholder="zu Seite"
            type="number"
            v-on:keyup.enter="navigateAbsolute"
          />
          <i
            title="Vollbild"
            v-on:click="fullscreen"
            v-bind:class="classFullscreenIcon"
          ></i>
        </div>
      </div>
      <div class="slide__image-container">
        <div class="slide__overlay"></div>
        <img
          v-bind:src="currentSlide.image.url"
          v-on:contextmenu.prevent
          v-on:dragstart.prevent
        />
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.components__cnt-slide_show {
  @import "~stylesheets/frontend/variables/colors";

  @media print {
    // hide content in print screen
    display: none;
  }

  &:focus {
    outline: none;
  }

  background-color: $color-black-very-light;

  img {
    max-width: 100%;
    max-height: 800px;

    background-color: white;
  }

  .slide__navigation {
    background-color: $color-black-placeholder;
    color: white;
    padding: 10px 0;

    display: flex;
    align-items: center;

    > div {
      flex-basis: 30%;
      flex-grow: 1;
      flex-shrink: 0;
    }

    .slide__navigation_section {
      display: flex;
      align-items: center;
    }
    .slide__navigation_section--center {
      justify-content: center;
    }
    .slide__navigation_section--right {
      justify-content: flex-end;
    }

    a,
    i {
      margin: 0 10px;
      color: white;
      cursor: pointer;
      user-select: none;
    }

    input {
      width: 60px;
      border: none;
      background-color: $color-black-very-light;
      color: $color-green;
      margin-right: 2px;

      text-align: left;
      &,
      &:focus {
        outline: none;
        border-radius: none;
      }
    }

    // hide arrows on number field
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    input[type="number"] {
      -moz-appearance: textfield;
    }
  }

  .slide__image-container {
    position: relative;
    text-align: center;
  }

  .slide__overlay {
    // remove drag and drop
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  &:fullscreen {
    img {
      max-height: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .not-authorized {
    display: flex;
    padding: 40px 30px;
    align-items: center;

    .frontend-icon {
      color: $color-green;
      margin-right: 15px;
      font-size: 30px;
    }
  }
}
</style>

<script>
import CntComponent from "../../mixins/cnt-component";

export default {
  mixins: [CntComponent],
  data() {
    return {
      currentSlideIndex: 0,
      isFullscreen: false,
    };
  },
  mounted() {
    this.setupFullscreenListener();
  },
  computed: {
    currentSlidePosition() {
      return this.currentSlideIndex + 1;
    },
    totalSlides() {
      return this.contentItem.widget_data.slide_show.slide_show_slides.length;
    },
    currentSlide() {
      return this.contentItem.widget_data.slide_show.slide_show_slides[
        this.currentSlideIndex
      ];
    },
    classFullscreenIcon() {
      const result = {
        "frontend-icon": true,
      };
      if (this.isFullscreen) {
        result["fi-fa_compress"] = true;
      } else {
        result["fi-fa_expand"] = true;
      }
      return result;
    },
    notAuthorized() {
      return (
        this.contentItem.widget_data.slide_show.needs_book_license &&
        !this.$store.state.gon.user.has_book_license
      );
    },
  },
  methods: {
    navigate(forward) {
      if (forward && this.currentSlideIndex < this.totalSlides - 1) {
        this.currentSlideIndex += 1;
      } else if (!forward && this.currentSlideIndex > 0) {
        this.currentSlideIndex -= 1;
      }
    },
    navigateAbsolute(event) {
      const newValue = parseInt(event.target.value, 10);
      event.target.value = null; // remove value

      let targetIndex =
        newValue -
        1 +
        (this.contentItem.widget_data.slide_show.slide_position_offset || 0);

      // sanitize new index
      targetIndex = Math.min(targetIndex, this.totalSlides - 1);
      targetIndex = Math.max(targetIndex, 0);

      if (
        targetIndex <
        this.contentItem.widget_data.slide_show.slide_position_offset
      )
        targetIndex = 0;

      this.currentSlideIndex = targetIndex;
    },
    fullscreen() {
      if (this.isFullscreen) {
        document.exitFullscreen();
      } else {
        this.$el.requestFullscreen();
      }
    },
    setupFullscreenListener() {
      document.addEventListener("fullscreenchange", () => {
        this.isFullscreen = document.fullscreenElement === this.$el;
      });
    },
    onWheel(event) {
      if (event.deltaY > 0) {
        this.navigate(true);
      } else if (event.deltaY < 0) {
        this.navigate(false);
      }
    },
  },
};
</script>
