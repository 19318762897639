<script>
import HttpMethod from 'vue/applications/shared/endpoints/utils/http-method';
import UniversalEndpoint from '../../endpoints/universal-endpoint';
import cntComponent from '../../mixins/cnt-component'

export default {
  mixins: [cntComponent],

  data: () => {
    return {
      userData: {
        lanr: null,
        is_member_of_bvkj: false,
        is_member_of_dgaap: false,
      },
      practiceData: {
        practice_name: null,
        practice_bsnr: null,
        practice_address: null,
        practice_zip: null,
        practice_city: null,
        practice_expertises: null,
        practice_training_permission_duration: null,
        role_type: null,
      },
      sending: false,
      errors: {},
      training_centre_type: '',
    }
  },

  computed: {
    selectedRole: function() {
      if (
        !this.contentItem ||
        !this.contentItem.widget_data ||
        !this.contentItem.widget_data.roles
      ) return null;

      return this.contentItem.widget_data.roles.find(role => role.id === this.practiceData.role_type);
    }
  },

  methods: {
    hanldeFormSubmit: async function(event) {
      event.preventDefault();
      console.log(JSON.parse(JSON.stringify(this.contentItem.widget_data)));
      if (this.sending) return;

      try {
        this.sending = true;
        this.errors = {};

        const endpoint = new UniversalEndpoint(this.$store);
        // try to upload role request
        const response = await endpoint.request(
          this.contentItem.widget_data.upload_role_request_path,
          HttpMethod.POST,
          {
            user: {
              ...this.userData,
              role_requests_attributes: [
                this.practiceData
              ]
            },
            training_centre_type: this.training_centre_type,
          }
        );

        if (response && response.data) {
          if (response.data.success) {
            // redirect
            location.href = this.contentItem.widget_data.redirect_target_after_save;
          } else {
            this.errors = response.data.errors;
          }
        }
      } finally {
        this.sending = false;
      }
    }
  },

  watch: {
    "training_centre_type": function() {
      // empty BSNR since only practices have it
      this.practiceData.practice_bsnr = null;
    },
    "practiceData.role_type": function() {
      // empty following fields since only a WBB have them
      this.userData.lanr = null;
      this.practiceData.practice_training_permission_duration = null;
      this.practiceData.practice_expertises = null;
    }
  }
}
</script>

<template>
  <div class="components__cnt-practice_data">
    <template v-if="contentItem">
      <div
        class="practice_data__not-logged-in"
        v-if="contentItem.widget_data.login_path"
      >
        Sie müssen sich zunächst
        <a
          v-bind:href="contentItem.widget_data.login_path"
        >
          einloggen oder registrieren
        </a>.
      </div>
      <div v-else class="generic-form registration-form">
        <template v-if="true">
          <div id="error_explanation">
            <ul>
              <li
                v-for="(error, indx) in this.errors"
                v-bind:key="indx"
              >
                {{ error[0] }}
              </li>
            </ul>
          </div>
        </template>
        <form v-on:submit="hanldeFormSubmit">
          <h3>Angaben zur Aus- und Weiterbildungsstätte</h3>

          <div class="form-row">
            <div class="form-stack">
              <label for="training_centre_type">Art der Aus- und Weiterbildungsstätte</label>
              <select v-model="training_centre_type" id="training_centre_type">
                <option disabled value="">Bitte auswählen</option>
                <option>Praxis</option>
                <option>Klinik</option>
              </select>
            </div>
          </div>

          <template v-if="training_centre_type != ''">
            <div class="form-row">
              <div v-bind:class="{ 'form-stack': true, 'practice_name': training_centre_type == 'Praxis', 'practice_name--large': training_centre_type != 'Praxis', 'field_with_errors': !!this.errors['role_requests.practice_name'] }">
                <label for="practice_name">Name der {{ training_centre_type }}</label>
                <input type="text" id="practice_name" v-model="practiceData.practice_name">
              </div>
              <template v-if="training_centre_type == 'Praxis'">
                <div class="form-stack practice_bsnr--short" v-bind:class="{ 'field_with_errors': !!this.errors['role_requests.practice_bsnr'] }">
                  <label for="practice_bsnr">BSNR der {{ training_centre_type }}</label>
                  <input type="number" maxlength="9" id="practice_bsnr" v-model="practiceData.practice_bsnr">
                </div>
              </template>
            </div>
            <div class="form-row">
              <div class="form-stack practice_address" v-bind:class="{ 'field_with_errors': !!this.errors['role_requests.practice_address'] }">
                <label for="practice_address">Straße und Hausnummer der {{ training_centre_type }}</label>
                <input type="text" id="practice_address" v-model="practiceData.practice_address">
              </div>
              <div class="form-stack practice_zip" v-bind:class="{ 'field_with_errors': !!this.errors['role_requests.practice_zip'] }">
                <label for="practice_zip">PLZ der {{ training_centre_type }}</label>
                <input type="number" maxlength="5" id="practice_zip" v-model="practiceData.practice_zip">
              </div>
              <div class="form-stack practice_city" v-bind:class="{ 'field_with_errors': !!this.errors['role_requests.practice_city'] }">
                <label for="practice_city">Ort der {{ training_centre_type }}</label>
                <input type="text" id="practice_city" v-model="practiceData.practice_city">
              </div>
            </div>
          </template>

          <h3>Angaben zu Ihrer Person</h3>

          <div class="form-row">
            <div class="form-stack">
              <label for="role_type">Ich möchte PaedCompenda verwenden als</label>
              <select v-model="practiceData.role_type" id="role_type">
                <option disabled v-bind:value="null">Bitte auswählen</option>
                <option
                  v-for="role in contentItem.widget_data.roles"
                  v-bind:key="role.id"
                  v-bind:value="role.id"
                >
                  {{ role.label }}
                </option>
              </select>
            </div>
          </div>

          <template v-if="practiceData.role_type == 'trainee'">
            <p>Die Angabe der Praxidaten und die Lizenzbestellung wird von Ihrer/Ihrem Weiterbildungsberechtigten vorgenommen. Bis zur Genehmigung der Lizenz können Sie kostenfrei die Schnupperversion verwenden.</p>
          </template>

          <template v-else-if="practiceData.role_type != null">
            <div class="form-row">
              <template v-if="selectedRole.prompt_lanr">
                <div class="form-stack lanr" v-bind:class="{ 'field_with_errors': !!this.errors['lanr'] }">
                  <label for="lanr">LANR</label>
                  <input type="number" id="lanr" v-model="userData.lanr">
                </div>
              </template>
              <template v-if="selectedRole.prompt_practice_training_permission_duration">
                <div class="form-stack practice_training_permission_duration" v-bind:class="{ 'field_with_errors': !!this.errors['role_requests.practice_training_permission_duration'] }">
                  <label for="practice_training_permission_duration">Dauer der Weiterbildungsberechtigung</label>
                  <input type="text" id="practice_training_permission_duration" v-model="practiceData.practice_training_permission_duration">
                </div>
              </template>
            </div>
            <div class="form-row">
              <template v-if="selectedRole.prompt_practice_expertises">
                <div class="form-stack practice_expertises" v-bind:class="{ 'field_with_errors': !!this.errors['role_requests.practice_expertises'] }">
                  <label for="practice_expertises">Schwerpunkte</label>
                  <input type="text" id="practice_expertises" v-model="practiceData.practice_expertises">
                </div>
              </template>
            </div>

            <div class="form-row checkboxes">
              <div class="form-checkbox">
                <input type="checkbox" id="is_member_of_bvkj" v-model="userData.is_member_of_bvkj">
                <label for="is_member_of_bvkj">Ich bin BVKJ-Mitglied</label>
              </div>
              <div class="form-checkbox">
                <input type="checkbox" id="is_member_of_dgaap" v-model="userData.is_member_of_dgaap">
                <label for="is_member_of_bvkj">Ich bin DGAAP-Mitglied</label>
              </div>
            </div>

            <div v-if="training_centre_type != ''" class="actions">
              <input class="button expanded" type="submit" value="Daten absenden">
            </div>
          </template>
        </form>
      </div>
    </template>
  </div>
</template>
