<script>
import cntComponent from '../../mixins/cnt-component'
export default {
  mixins: [cntComponent]
}
</script>

<template>
    <pre>
      {{ this.contentItem.widget_data.error_code }}
    </pre>
</template>
