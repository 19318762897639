import { Store } from "vuex";

import PubSubInterface from "vue/applications/author/pubsub/pub-sub-interface";
import RootState from "vue/applications/author/store/root-state";
import SharedEndpoint from "vue/applications/shared/endpoints/endpoint";

export default abstract class Endpoint extends SharedEndpoint {
  // Creates an instance, but needs the store of the application
  constructor(vuexStore: Store<RootState>) {
    super(vuexStore);
  }

  protected createPubSubInterface() {
    return new PubSubInterface();
  }

  protected getCSRFToken(): string {
    return this.$store.state.session.csrf_token;
  }
}
