export default {
  data() {
    return {
      syncedSlotNames: [],
    };
  },
  mounted() {
    this.syncedSlotNames = [];
    for (const key of Object.keys(this.$slots)) {
      if (key.indexOf("synced-input-") === 0) this.syncedSlotNames.push(key);
    }
  },
};
