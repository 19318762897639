<script>
import ThemingMixin from "vue/applications/frontend/mixins/theming-mixin";

export default {
  mixins: [ThemingMixin],
  props: {
    scoreLevel: {
      type: Object,
      default: null,
    },
    currentValue: {
      type: Number,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    hiddenIfInactive: {
      type: Boolean,
      default: false,
    },
    light: {
      type: Boolean,
      default: false,
    },
    black: {
      type: Boolean,
      default: false,
    },
    tooltipPrefix: {
      type: String,
      default: "",
    },
  },
  computed: {
    isActive() {
      return this.active || this.scoreLevel.value === this.currentValue;
    },
    bubbleCssClasses() {
      const result = [];

      result.push(this.themingCssClass("border"));

      if (this.isActive) {
        result.push("bubble-is-selected");
        result.push(this.themingCssClass("inverted"));
      } else {
        result.push("bubble-is-not-selected");
        result.push(this.themingCssClass("normal"));
      }

      if (this.light) {
        result.push("light");
      }

      if (this.black) {
        result.push("black");
      }

      result.push(this.scoreLevel.item_type);

      // [
      //   { active: active || scoreLevel.value == currentValue, light },
      //   scoreLevel.item_type,
      // ]

      return result;
    },
  },
};
</script>

<template>
  <div class="components__score-item-bubble">
    <div
      v-bind:style="{
        visibility:
          hiddenIfInactive && !active && scoreLevel.value !== currentValue
            ? 'hidden'
            : 'initial',
      }"
      v-on:click="$emit('trigger')"
      v-if="scoreLevel"
      v-bind:class="bubbleCssClasses"
      v-bind:title="tooltipPrefix + scoreLevel.tooltip"
    >
      {{ scoreLevel.label }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "~stylesheets/frontend/variables/colors";

.components__score-item-bubble {
  > div {
    &.bubble-is-not-selected {
      background-color: colors.$color-white;
    }
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 4px;
    font-size: 16px;
    user-select: none;

    &.light {
      background-color: colors.$color-white;
      $_light_color: colors.$color-black-very-light;
      $_light_color: colors.$color-black-border;
      border: 1px solid $_light_color;
      color: $_light_color;
      &.active {
        background-color: $_light_color;
      }
    }

    &.black {
      background-color: black;
      border: 1px solid white;
      color: white;
    }

    cursor: pointer;

    &.yes_no {
      font-size: 14px;
    }

    &.active {
      color: colors.$color-white;
      background-color: colors.$color-green;
    }

    &.hidden {
      visibility: hidden;
    }
  }
}
</style>
