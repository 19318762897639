<script>
export default {
  props: {
    target: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selection: {
      type: Object,
      default: null,
    },
    selectionClassName: {
      type: String,
      default: "href-target--selected",
    },
  },
  data() {
    return {
      selected: this.selection?.selected,
    };
  },
  methods: {
    handleClick(event) {
      // elements with class "ignore-click" won't open target by default
      if (event.target.closest('.ignore-click')) {
        event.preventDefault();
        return;
      }

      // goto target
      if (this.target && !this.disabled) {
        location.href = this.target;
      }

      // selectable
      if (this.selection) {
        this.selected = !this.selected;
      }
    },
  },
};
</script>

<template>
  <div
    class="components__href-target"
    v-on:click="handleClick"
    v-bind:class="{
      'href-target--disabled': disabled,
      'href-target--active': !disabled && target,
      [selectionClassName]: selected,
    }"
  >
    <input
      class="selection-checkbox"
      v-if="selection"
      type="checkbox"
      v-bind:name="selection.name"
      v-bind:value="selection.value"
      v-bind:checked="selected"
    />
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.href-target--disabled {
  cursor: not-allowed;
}

.href-target--selected {
}

.selection-checkbox {
  display: none;
}
</style>
