<template>
  <div class="components__rec-tabbed-conted">
    <tabbed-content v-bind:tab-headers="tabHeaders">
      <template v-for="tab in description.tabs" v-slot:[tab.key]>
        <component
          v-bind:key="tab.key"
          v-bind:is="tab.content.component"
          v-bind:description="tab.content"
          v-on:submit="$emit('submit')"
        />
      </template>
    </tabbed-content>
  </div>
</template>

<script>
import RecComponent from "../../mixins/rec-component";

export default {
  mixins: [RecComponent],
  computed: {
    tabHeaders() {
      const result = {};
      for (const tab of this.description.tabs || []) {
        result[tab.key] = tab.heading;
      }
      return result;
    },
  },
};
</script>
