<template>
  <div class="components__competencies-overview">
    <template class="competency" v-for="competency in competencies">
      <competency-accordion v-bind:key="competency.id" v-bind:competency="competency"></competency-accordion>
    </template>
  </div>
</template>

<script>
export default {
  computed: {
    competencies() {
      return this.$store.state.gon.competencies;
    },
  },
};
</script>
