<template>
  <div class="components__rec-history">
    <simple-button
      use-theming
      class="history-button"
      v-bind:key="entry.id"
      v-for="entry in description.content.entries"
      v-bind:link-target="entry.target_path"
      v-bind:filled="entry.active"
      >{{ entry.label }}</simple-button
    >
  </div>
</template>

<style lang="scss" scoped>
.components__rec-history {
  display: flex;
  flex-wrap: wrap;

  .history-button {
    margin-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;

    font-size: 14px;
  }
}
</style>

<script>
import RecComponent from "../../mixins/rec-component";

export default {
  mixins: [RecComponent],
};
</script>
