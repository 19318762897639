<template>
  <div class="components__rec-rich-text">
    <span class="rich-text-content" v-html="description.content"></span>
  </div>
</template>

<style lang="scss">
.components__rec-rich-text {
  .rich-text-content {
    &,
    * {
      font-size: 16px;
    }
  }
}
</style>

<script>
import RecComponent from "../../mixins/rec-component";

export default {
  mixins: [RecComponent],
};
</script>
