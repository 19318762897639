<script>
import ThemingMixin from "vue/applications/frontend/mixins/theming-mixin";

export default {
  mixins: [ThemingMixin],
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "mehr",
    },
  },
};
</script>

<template>
  <div
    class="components__score-item-show-more-button more"
    v-bind:class="[themingCssClass('normal')]"
    v-on:click="$emit('input', !value)"
  >
    <span class="more-label">{{ label }}</span>
    <span class="icon">
      <template v-if="value">
        <i class="frontend-icon fi-expand_less"></i>
      </template>
      <template v-else>
        <i class="frontend-icon fi-expand_more"></i>
      </template>
    </span>
  </div>
</template>

<style lang="scss" scoped>
@import "~stylesheets/frontend/variables/colors";
.components__score-item-show-more-button {
  &.more {
    font-size: 14px;
    cursor: pointer;
    margin-top: 15px;

    .more-label {
      margin-right: 5px;
    }
  }
}
</style>
