<script>
import Vue from "vue";

export default {
  data: () => ({
    expanded: {
    },
  }),
  computed: {
    content() {
      // debugger;
      return this.$store.state.gon.sidebar?.content;
    },
    currentPath() {
      return window.location.pathname;
    },
  },
  methods: {
    toggle(index) {
      Vue.set(this.expanded, index, !this.expanded[index]);
    },
    isExpanded(index) {
      if(typeof(this.expanded[index]) === 'undefined' && this.initiallyExpanded(index)) {
        // sideeffect!
        Vue.set(this.expanded, index, true);
      }
      return !!this.expanded[index];
    },
    isActive(entry) {
      return entry.target && !entry.expand && ((entry.target ?? '').split('?')[0] === this.currentPath);
    },
    initiallyExpanded(index) {
      if (this.content == undefined) return false;
      return this
              .content[index]
              .children
              .map((child) => [child, ...(child.expand || [])])
              .flat()
              .some((entry) => this.isActive(entry));
    }
  }
};
</script>

<template>
  <div id="sidebar-navigation" class="components__side-bar print--hide" v-if="this.content">
    <div class="menu-button-close">
      <a href="#sidebar-navigation-close">
        <i class="frontend-icon fi-fa_times"></i>
      </a>
    </div>
    <template v-for="(section, index) in content">
      <div class="title" v-bind:title="section.title" v-bind:class="{'extra-top-space': (index !== 0), 'title--accordion': section.accordion }" v-on:click="toggle(index)">
        <span class="title__label">{{ section.title }}</span>
        <i v-if="section.accordion" class="frontend-icon" v-bind:class="{'fi-expand_more': !isExpanded(index), 'fi-expand_less': isExpanded(index)}"></i>
      </div>
      <div v-if="section.children && (!section.accordion || isExpanded(index))" class="entries">
        <template v-for="entry in section.children">
          <div
            class="entry"
            v-bind:class="{active: isActive(entry)}"
          >
            <a v-if="entry.target && !entry.expand" v-bind:href="entry.target">{{entry.label}}</a>
            <span class="entry-without-target" v-else>{{entry.label}}</span>
          </div>
          <template v-if="entry.expand">
            <div
              v-for="subentry in entry.expand"
              class="sub-entry"
              v-bind:class="{active: isActive(subentry)}"
            >
              <a v-bind:href="subentry.target">{{subentry.label}}</a>
            </div>
          </template>
        </template>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@import "~stylesheets/frontend/variables/components/side-bar";
@import "~stylesheets/frontend/variables/components/top-bar";
@import "~stylesheets/frontend/variables/colors";

@import "~include-media/dist/include-media";

$breakpoints: (
  "sidebar": $side-bar_breakpoint,
);

.components__side-bar {
  &#sidebar-navigation {
    width: $side-bar_width;
    min-width: $side-bar_width;
    min-height: calc(100vh - #{$top-bar_height});
    box-sizing: border-box;

    &:target {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      z-index: 99;

      width: 100%;
      height: 100%;

      .menu-button-close {
        display: block;
      }
    }
  }

  $_sidebar_padding-left: 21px;

  .menu-button-close {
    display: none;
    padding-left: $_sidebar_padding-left;
  }

  background-color: $color-white;
  padding-top: 17px;

  .title {
    margin-left: $_sidebar_padding-left;
    margin-top: 12px;
    margin-bottom: 21px;
    font-size: 18px;
    color: $color-green;

    display: flex;
    align-items: center;

    &.extra-top-space {
      margin-top: 52px;
    }
  }

  .title__label {
    display: inline-block;
    overflow:hidden;
    text-overflow: ellipsis;
    white-space:nowrap;

    @include media(">=sidebar") {
      width: 212px;
    }
  }

  .title--accordion {
    user-select: none;
    cursor: pointer;
  }

  .entries {
    div {
      color: $color-default;
    }
  }

  .entry,
  .sub-entry {
    $_entry-border: 1px solid $color-black-very-light;
    padding-left: $_sidebar_padding-left;
    border-top: $_entry-border;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-right: 10px;

    &:last-of-type {
      border-bottom: $_entry-border;
    }

    &.active {
      background-color: $color-green-hueish;
    }

    &.sub-entry {
      padding-left: $_sidebar_padding-left + 15px;
      color: $color-white_secondary-text;
    }
  }
}
</style>
