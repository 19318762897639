<template>
  <div class="components__cnt-table_privacy">
    <table v-if="contentItem">
      <tr>
        <td valign="middle" class="privacy-table__descriptive">
          <div class="privacy-table__descriptive-wrapper">
            <img src="../../../../../images/cnt-widgets/privacy--disk.png" />
            Datenarten
          </div>
        </td>
        <td>
          <div v-html="contentItem.widget_data.data_types"></div>
        </td>
      </tr>
      <tr>
        <td class="privacy-table__descriptive">
          <div class="privacy-table__descriptive-wrapper">
            <img
              src="../../../../../images/cnt-widgets/privacy--paper-plane.png"
            />
            Weitergabe von Daten
          </div>
        </td>
        <td>
          <div v-html="contentItem.widget_data.data_circulation"></div>
        </td>
      </tr>
      <tr>
        <td class="privacy-table__descriptive">
          <div class="privacy-table__descriptive-wrapper">
            <img
              src="../../../../../images/cnt-widgets/privacy--download.png"
            />
            Cloudnutzung
          </div>
        </td>
        <td>
          <div v-html="contentItem.widget_data.cloud_usage"></div>
        </td>
      </tr>
      <tr>
        <td class="privacy-table__descriptive">
          <div class="privacy-table__descriptive-wrapper">
            <img src="../../../../../images/cnt-widgets/privacy--eye.png" />
            Tracking
          </div>
        </td>
        <td>
          <div v-html="contentItem.widget_data.tracking"></div>
        </td>
      </tr>
      <tr>
        <td class="privacy-table__descriptive">
          <div class="privacy-table__descriptive-wrapper">
            <img src="../../../../../images/cnt-widgets/privacy--mail.png" />
            Werbung
          </div>
        </td>
        <td>
          <div v-html="contentItem.widget_data.advertisments"></div>
        </td>
      </tr>
    </table>
  </div>
</template>

<style lang="scss" scoped src="stylesheets/lib/_cnt-table_privacy.scss"></style>

<script>
import CntComponent from "../../mixins/cnt-component";

export default {
  mixins: [CntComponent],
};
</script>

