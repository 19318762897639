import { MutationTree } from "vuex";

import MutationType from "./mutation-type";
import State from "./state";

const mutations: MutationTree<State> = {
  [MutationType.resetFilter](state: State) {
    for (const filter of (state as any).definitions) {
      filter.checked = filter.initial_value;
    }
  },
};

export default mutations;
