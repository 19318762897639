<template>
  <div
    class="components__cnt-richt_text"
    v-bind:class="themingCssClasses(['sub_headline', 'sub_link'])"
  >
    <template v-if="contentItem">
      <div v-html="contentItem.widget_data.content"></div>
    </template>
  </div>
</template>

<script>
import CntComponent from "../../mixins/cnt-component";
import ThemingMixin from "vue/applications/frontend/mixins/theming-mixin";

export default {
  mixins: [CntComponent, ThemingMixin],
};
</script>
