<script>
import ThemingMixin from "vue/applications/frontend/mixins/theming-mixin";

export default {
  mixins: [ThemingMixin],
  props: {
    scoreItem: {
      type: Object,
    },
  },
  data: () => {
    return {
      currentValue: null,
      values: [
        { key: 1, value: 1, label: 1 },
        { key: 2, value: 2, label: 2 },
        { key: 3, value: 3, label: 3 },
        { key: 4, value: 4, label: 4 },
      ],
      showMore: false,
      isCollapsed: true,
    };
  },
  mounted() {
    if (this.scoreItem) {
      this.currentValue = this.scoreItem.ivalue;
      if(this.scoreItem.free_text) this.currentValue = this.currentValue ?? this.scoreItem.previous_scores[0]?.value
    }
  },
  computed: {
    name() {
      if (this.scoreItem && this.currentValue != null) {
        return `${this.scoreItem.name_prefix}[${this.scoreItem.id}]`;
      }
      return null;
    },
    lastPreviousScore() {
      if (this.scoreItem && this.scoreItem.skip_last_previous_score)
        return null;
      const previousScores = this.scoreItem.previous_scores || [];
      if (previousScores.length > 0) {
        return previousScores[0];
      }
      return null;
    },
    filtered() {
      // filtering is not even active
      if (!this.$store.getters.filteringActive) {
        return false;
      }

      // Dont filter out required items
      if ((this.scoreItem || {}).required) {
        return false;
      }

      // get filters preprocessed from store
      const filtersFromStoreAsIDChecked = this.$store.getters.filterIDToChecked;

      // We dont have previous scores
      if (((this.scoreItem || {}).previous_scores || []).length <= 0) {
        return !filtersFromStoreAsIDChecked[-1];
      }

      // Get last score from previous scores
      const lastScore = [...this.scoreItem.previous_scores].reverse().pop();

      // get correctFilter
      const correctFilter =
        filtersFromStoreAsIDChecked[lastScore.score_level_id];

      if (correctFilter == null) {
        // undefined or null
        return false;
      }

      return !correctFilter;
    },
    collapsible() {
      return !this.scoreItem.readonly && this.scoreItem.is_collapsed;
    },
    collapsed() {
      return this.collapsible && this.isCollapsed;
    },
    hasAdditionalInfo() {
      return (this.scoreItem?.info_items || []).length > 0;
    },
    moreLabel() {
      if (!this.scoreItem.cumulate_scores) return null;  // don't show history if scores are not cumulatd
      if(this.hasAdditionalInfo && (this.scoreItem.show_all_previous_values || !this.usesPreviousValues) ) return "weitere Informationen";
      if(this.hasAdditionalInfo && this.usesPreviousValues) return "letzte Einträge / weitere Informationen";
      if(this.usesPreviousValues) return "letzte Einträge";
      return null;
    },
    layout() {
      return this.scoreItem.free_text ? 'stacked-layout' : 'left-right-layout';
    },
    transmittedValue() {
      if(this.scoreItem.free_text && this.lastPreviousScore?.value === this.currentValue) return null;
      else return this.currentValue;
    },
    usesPreviousValues() {
      return !!this.usesUserInput;
    },
    showAllPreviousValues() {
      return !!this.scoreItem.show_all_previous_values && !!this.scoreItem.free_text;
    },
    usesUserInput() {
      return !!this.scoreItem.user_input;
    },
    shownSyncedScores() {
      if(!this.scoreItem.synced_scores) return [];

      return this.scoreItem.synced_scores.map((scoreItem) => ({
        ...scoreItem,
        scores: scoreItem.scores.length > 0 ? scoreItem.scores[0] : null,
      }));
    }
  },
  methods: {
    selectItem(value) {
      if (this.scoreItem.readonly) return;
      else if (value && value.value === this.currentValue) {
        // already selected?
        this.currentValue = null;
      } else if (value) {
        this.currentValue = value.value;
      } else this.currentValue = null;
    },
  },
};
</script>

<template>
  <div class="components__score-item print--avoid-page-break">
    <div
      class="hueish"
      v-bind:class="[{ filtered }, themingCssClass('light_background')]"
    >
      <input
        v-if="usesUserInput && name"
        type="hidden"
        v-bind:name="name"
        v-bind:value="transmittedValue"
      />
      <template v-if="collapsed">
        <div
          class="text collapse-text"
          v-bind:class="[themingCssClass('normal')]"
          v-on:click="isCollapsed = !isCollapsed"
        >
          <p class="collapse-text__label">
            {{ scoreItem.text }}
          </p>
          einblenden
          <template v-if="transmittedValue">
            <br/>
            (bereits bearbeitet)
          </template>
        </div>
      </template>
      <template v-else-if="(filtered)">
        <div
          class="text filter-text"
          v-bind:class="[themingCssClass('normal')]"
        >
          Dieses Element wurde aufgrund des gewählten Filters ausgeblendet.
      </div>
      </template>
      <template v-else>
        <component v-bind:is="layout">
          <template v-slot:label>
            <div class="text" v-if="scoreItem.text !== '.'">
              {{ scoreItem.text }}
              <template v-if="scoreItem.required">*</template>
              <div v-if="collapsible"
                class="text collapse-text"
                v-bind:class="[themingCssClass('normal')]"
                v-on:click="isCollapsed = !isCollapsed">
                ausblenden
              </div>
            </div>
          </template>
          <template v-if="usesUserInput" v-slot:user-input>
            <score-item-text v-if="scoreItem.free_text" v-model="currentValue" v-bind:readonly="scoreItem.readonly" />
            <score-item-text v-if="showAllPreviousValues" v-for="value in (scoreItem.previous_scores || []).slice(1)" v-bind:key="value.id" v-model="value.value" v-bind:readonly="scoreItem.readonly" />
            <score-item-bubble
            class="bubble bubble--user-input"
              v-if="scoreItem.score_levels"
              v-for="scoreLevel in scoreItem.score_levels"
              v-bind:key="'user-input--' + scoreLevel.id"
              v-on:trigger="selectItem(scoreLevel)"
              v-bind:score-level="scoreLevel"
              v-bind:current-value="currentValue"
            />
          </template>
          <template v-if="scoreItem.keywords_html" v-slot:keywords>
            <span class="keywords" v-html="scoreItem.keywords_html"></span>
          </template>
          <template v-if="lastPreviousScore" v-slot:previous-input>
            <score-item-text v-if="scoreItem.free_text && lastPreviousScore.value !== currentValue" label="Letzter Eintrag" readonly v-bind:value="lastPreviousScore.value" light/>
            <score-item-bubble
              class="bubble bubble--previous-input"
              light
              v-if="scoreItem.score_levels"
              v-for="scoreLevel in scoreItem.score_levels"
              v-bind:key="'previous-input--' + scoreLevel.id"
              v-bind:score-level="scoreLevel"
              v-bind:current-value="lastPreviousScore.value"
              v-bind:hidden-if-inactive="true"
              tooltip-prefix="Letzter Eintrag: "
            />
          </template>
          <template v-for="si in shownSyncedScores" v-slot:[`synced-input-${si.score_item_id}`]>
            <template v-if="si.scores">
              <score-item-text v-if="si.free_text" label="Externe Einschätzung" readonly v-bind:value="si.scores.value" light/>
              <score-item-bubble
              class="bubble bubble--synced-input"
              black
              v-if="si.score_levels"
              v-for="scoreLevel in si.score_levels"
              v-bind:key="'previous-input--' + scoreLevel.id"
              v-bind:score-level="scoreLevel"
              v-bind:current-value="si.scores.value"
              v-bind:hidden-if-inactive="true"
              tooltip-prefix="Externe Einschätzung: "
            />
            </template>
          </template>
          <template v-slot:more-button v-if="moreLabel">
            <score-item-show-more-button
              class="more-button print--hide"
              v-bind:label="moreLabel"
              v-model="showMore"
            />
          </template>
        </component>
      </template>
    </div>
    <template v-if="!filtered">
      <div class="more-text" v-bind:class="{ active: showMore }">
        <tabbed-content
          v-bind:tab-headers="{
            last: 'letzte Einträge',
            information: 'Weitere Informationen',
          }"
        >
          <template v-slot:last v-if="usesPreviousValues && !showAllPreviousValues">
            <div class="tab-content">
              <template v-if="(scoreItem.previous_scores || []).length > 0">
                <div class="previous-scores"
                v-bind:class="{'previous-scores--free-text': scoreItem.free_text}">
                  <div
                    class="previous-score-container"
                    v-for="(previousScore, index) in scoreItem.previous_scores"
                    v-bind:class="{'previous-score-container--free-text': scoreItem.free_text}"
                    v-bind:key="previousScore.id"
                  >
                    <div class="previous-score-bubble"
                    v-bind:class="{'previous-score-bubble--free-text': scoreItem.free_text}">
                      <score-item-text readonly v-if="scoreItem.free_text" v-bind:value="previousScore.value" light/>
                      <score-item-bubble v-else v-bind:score-level="previousScore" />
                    </div>
                    <div>{{ previousScore.exam_taken_at }}</div>
                  </div>
                </div>
              </template>
              <template v-else>Bisher noch nicht bewertet</template>
            </div>
          </template>
          <template v-if="hasAdditionalInfo" v-slot:information>
            <div class="tab-content">
              <ul>
                <li
                  v-for="infoItem in scoreItem.info_items"
                  v-bind:key="infoItem.id"
                >
                  <info-item v-bind:info-item="infoItem"></info-item>
                </li>
              </ul>
            </div>
          </template>
        </tabbed-content>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
@use "~stylesheets/frontend/variables/colors";
@import "~include-media/dist/include-media";

$breakpoints: (
  "tablet": 1000px,
);

.components__score-item {
  .hueish {
    padding: 19px;
    border-bottom: 2px solid colors.$color-white;
  }

  .filter-text, .collapse-text{
    font-size: 12px;
    line-height: 12px;
  }

  .collapse-text {
    cursor: pointer;
  }

  .free-text {
    margin-top: 20px;
    margin-bottom: 20px;

    width: 100%;
    resize: none;
  }

  .bubble {
    margin-right: 16px;
    margin-left: 16px;

    &:first-of-type {
      margin-left: 0;
    }
    &:last-of-type {
      margin-right: 0;
    }

    @include media("<tablet") {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    @media print {
      margin-top: 10px !important;
      margin-bottom: 0 !important;
      margin-right: 0 !important;
    }
  }

  .bubble--user-input {
    margin-bottom: 20px;
  }

  .text {
    @media print {
      font-size: 14px;
    }
  }

  .more-text {
    display: none;
    padding: 24px;

    &.active {
      display: block;
    }

    .tab-content {
      font-size: 16px;
      color: colors.$color-default;

      .previous-scores {
        display: flex;
        flex-wrap: wrap;
        max-width: 820px;

        &--free-text {
          display: block;
          max-width: 100%;
          width: 100%;
        }

        .previous-score-container {
          display: flex;
          align-items: center;
          width: 200px;
          margin-top: 5px;
          margin-bottom: 5px;

          &--free-text {
            width: 100%;
            flex-direction: row-reverse;
            justify-content: start;
          }

          .previous-score-bubble {
            margin-right: 10px;

            &--free-text {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}

.item--edited {
  > i { font-size: 16px; }
  margin-top: 10px;
  align-content: center;
}

.keywords {
    display: block;

    ::v-deep * {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 16px;
      line-height: 26px;
    }
  }
</style>
