<script>
import RecComponent from "../../mixins/rec-component";

export default {
  mixins: [RecComponent],
  computed: {
    asLines() {
      if (!this.description.ivalue) return [];
      return this.description.ivalue.split("\n");
    },
  },
};
</script>

<template>
  <div class="components__rec-text-field">
    <div
      v-if="description.title"
      class="text-field-title"
      v-bind:class="[themingCssClass('normal')]"
    >
      {{ description.title }}
    </div>
    <textarea
      v-bind:rows="description.rows"
      v-bind:name="description.name"
      v-model="description.ivalue"
      v-if="!description.readonly"
    ></textarea>
    <p>
      <template v-if="description.readonly" v-for="line in asLines">
        <span>{{ line }}</span>
        <br />
      </template>
    </p>
  </div>
</template>

<style lang="scss" scoped>
.components__rec-text-field {
  textarea {
    width: 100%;
    resize: vertical;
  }

  .text-field-title {
    margin-bottom: 10px;
    margin-top: 20px;
  }
}
</style>
