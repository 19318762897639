<template>
  <div class="components__generic-accordion">
    <!-- header - the clickable part -->
    <div class="accordion-header-container" v-on:click="toggle">
      <div>
        <slot name="header">HEADER</slot>
      </div>
      <div><slot name="chevron"><i class="accordion-chevron frontend-icon" v-bind:class="chevronClass"></i></slot></div>
    </div>
    <!-- content - the toggable content -->
    <slot v-if="showContent" name="content">CONTENT</slot>
  </slot>
  </div>
</template>

<style lang="scss" scoped>
.components__generic-accordion {
  .accordion-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .accordion-chevron {
      font-size: 20px;
    }
  }
}
</style>

<script>
export default {
  props: {
    // v-model
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      internalState: false,
    };
  },
  computed: {
    showContent() {
      // we use xor here, so when one of the two variables flips,
      // the output flips
      return !!this.internalState;
    },
    chevronClass() {
      if (this.showContent) {
        return "fi-expand_less";
      } else {
        return "fi-expand_more";
      }
    },
  },
  methods: {
    toggle() {
      this.$emit("input", !this.internalState);
      this.internalState = !this.internalState;
    },
  },
  watch: {
    value: {
      handler(newValue) {
        this.internalState = newValue;
      },
      immediate: true,
    },
  },
};
</script>
