import { render, staticRenderFns } from "./simple-datepicker.vue?vue&type=template&id=23f32b70&scoped=true"
import script from "./simple-datepicker.vue?vue&type=script&lang=js"
export * from "./simple-datepicker.vue?vue&type=script&lang=js"
import style0 from "./simple-datepicker.vue?vue&type=style&index=0&id=23f32b70&prod&lang=scss&scoped=true"
import style1 from "./simple-datepicker.vue?vue&type=style&index=1&id=23f32b70&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23f32b70",
  null
  
)

export default component.exports