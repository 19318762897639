<template>
  <div class="components__rec-check-box">
    <label>
      <input
        v-bind:name="description.name"
        type="checkbox"
        v-model="description.ivalue"
        v-bind:disabled="description.disabled"
        v-bind:required="!!description.frontend_only"
      />
      <span v-if="description.label">{{ description.label }}</span>
    </label>
  </div>
</template>

<style lang="scss" scoped>
.components__rec-check-box {
  label {
    > input {
      vertical-align: middle;
      margin-right: 10px;
    }
    > span {
      vertical-align: middle;
    }
  }
}
</style>

<script>
import RecComponent from "../../mixins/rec-component";

export default {
  mixins: [RecComponent],
};
</script>
