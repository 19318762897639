export default {
  methods: {
    themingCssClass(selectorName): string {
      return this.$store.getters.themingCssClass(selectorName);
    },
    themingCssClasses(selectorNames: string[]) {
      return selectorNames.map((selectorName) =>
        this.themingCssClass(selectorName),
      );
    },
  },
};
