<template>
  <div class="components__back-button sheet-close-button print--hide" v-on:click="goBack">
    <!-- <i class="frontend-icon fi-close"></i> -->
    <span class="sheet-close-button__label">Zurück</span>
  </div>
</template>

<style lang="scss" scoped>
.components__back-button {
  float: right;
  cursor: pointer;

  .sheet-close-button__label {
    font-size: 16px;
    vertical-align: middle;
  }
}
</style>

<script>
export default {
  methods: {
    goBack() {
      window.history.back();
    },
  },
};
</script>
