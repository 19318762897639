<template>
  <div class="components__side-bar-button menu-button print--hide" v-bind:class="{padded}">
    <a href="#sidebar-navigation" class="link">
      <i class="frontend-icon fi-fa_bars"></i>
    </a>
  </div>
</template>

<style lang="scss" scoped>
@import "~stylesheets/frontend/variables/routes/content-page";
@import "~stylesheets/frontend/variables/components/side-bar";

.components__side-bar-button {
  cursor: pointer;
  margin-bottom: 20px;

  &.padded {
    position: relative;
    height: 30px;

    .link {
      position: absolute;
      top: $content-page_padding-top_mobile;
      left: $content-page_padding-left_mobile;
    }
  }
}
</style>

<script>
export default {
  props: {
    padded: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
