<script>
import RecComponent from "../../mixins/rec-component";

export default {
  mixins: [RecComponent],
  methods: {
    themingExamCssClass(exam) {
      if (!exam.active) return this.themingCssClass("normal");
      return null;
    },
  },
};
</script>

<template>
  <div class="components__rec-history-causes">
    <template v-if="(description.causes || []).length > 0">
      <p>Folgende Vorstellungsanlässe wurden bereits bearbeitet:</p>
      <ul>
        <li
          class="cause-li"
          v-for="cause in description.causes"
          v-bind:key="cause.key"
        >
          {{ cause.label }}:
          <span v-for="(exam, index) in cause.exams" v-bind:key="exam.key">
            <a
              v-bind:class="[themingExamCssClass(exam)]"
              v-bind:href="exam.target"
              >{{ exam.label }}</a
            ><template v-if="index != cause.exams.length - 1">,&#32;</template>
          </span>
        </li>
      </ul>
    </template>
    <template v-else>
      <p>Es wurden noch keine Vorstellungsanlässe bearbeitet.</p>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.components__rec-history-causes {
  p,
  ul,
  li {
    font-size: 16px;
  }
}
</style>
