import Endpoint from "./endpoint";

import HttpMethod from "vue/applications/shared/endpoints/utils/http-method";
import RequestPayload from "vue/applications/shared/endpoints/utils/payload";
import RequestAction from "vue/applications/shared/endpoints/utils/request-action";
import RequestResult from "vue/applications/shared/endpoints/utils/request-result";
import getMetaTag from "vue/utils/get-meta-tag";

export default class UniversalEndpoint extends Endpoint {
  public static topicName() {
    return "UniversalEndpoint";
  }

  public async request(
    path: string,
    method: HttpMethod,
    payload?: RequestPayload,
    params?: object,
  ): Promise<RequestResult> {
    return await this.requesting(
      new RequestAction(payload, params).setPath(path).setMethod(method),
    );
  }

  protected getCSRFToken(): string {
    return getMetaTag("csrf-token");
  }
}
