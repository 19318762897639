<template>
  <div class="components__simple-datepicker">
    <label>
      <div class="datepicker-label">
        <slot />
      </div>
      <input
        placeholder="Datum wählen"
        ref="target"
        v-bind:name="name"
        v-bind:value="value"
      />
    </label>
  </div>
</template>

<style lang="scss" scoped>
.components__simple-datepicker {
  label {
    > div {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
}
</style>

<style lang="scss">
@import "flatpickr/dist/flatpickr.css";
.components__simple-datepicker {
  input[type="text"] {
    width: 100px;
    text-align: center;
    cursor: pointer;
  }
}
</style>

<script>
import flatpickr from "flatpickr";
import { German } from "flatpickr/dist/l10n/de.js";

export default {
  data() {
    return {
      picker: null,
    };
  },
  props: {
    name: {
      // name attribute
      type: String,
      default: null,
    },
    value: {
      // v-model
      type: [Date, String],
      default: function () {
        return new Date();
      },
    },
    future: {
      type: Boolean,
      default: false,
    },
    minDate: {
      type: Date,
      default: null,
    },
    skipDefaultValue: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.resetPicker();

    const handleDateChange = (selectedDates, dateStr, instance) => {
      this.$emit("input", dateStr);
    };

    const options = {
      locale: German,
      altFormat: "d.m.Y",
      altInput: true,
      defaultDate: this.skipDefaultValue
        ? null
        : this.value || this.minDate || new Date(),
      onValueUpdate: handleDateChange,
      onReady: handleDateChange,
      onChange: handleDateChange,
    };

    // Future dates allowed?
    if (!this.future) {
      options.maxDate = new Date();
    }

    if (this.minDate) {
      options.minDate = this.minDate;
    }

    this.picker = flatpickr(this.$refs.target, options);
  },
  beforeDestroy() {
    this.resetPicker();
  },
  methods: {
    resetPicker() {
      if (this.picker) {
        this.picker.destroy();
        this.picker = null;
      }
    },
    updatePickerDisabled() {
      if (!this.picker) return;

      if (this.disabled)
        this.picker._input.setAttribute("disabled", "disabled");
      else this.picker._input.removeAttribute("disabled");
    },
  },
  watch: {
    disabled: function () {
      this.updatePickerDisabled(this.disabled);
    },
    picker: function () {
      this.updatePickerDisabled(this.disabled);
    },
  },
};
</script>
