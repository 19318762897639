<script lang="ts">
export default {
  props: {
    readonly: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: "",
    },
    light: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
  },
  emits: ["input"],
  computed: {
    valueLines() {
      return typeof(this.value) === 'string' ? this.value.split(/\r?\n/) : this.value;
    }
  },
};
</script>

<template>
  <div class="score-item-text" v-bind:class="{'score-item-text--extra-top-space': !!label}">
    <label class="score-item-text__label" v-if="label">{{label}}</label>
    <div
      class="score-item-text__text-readonly"
      v-bind:class="{'score-item-text__text-readonly--light': light}"
      v-if="readonly && value != null"
    >
      <template v-for="line in valueLines">
        {{line}}<br>
      </template>
    </div>
    <textarea
      v-else-if="!readonly"
      rows="5"
      class="score-item-text__textarea"
      v-bind:value="value"
      v-on:input="$emit('input', $event.target.value)"
    />
    <div v-else>&hyphen;</div>
  </div>
</template>

<style lang="scss" scoped>
  @use "sass:color";
  @use "~stylesheets/frontend/variables/colors";

.score-item-text {
  margin: 7px 0;
}

.score-item-text--extra-top-space {
  margin-top: 15px;
}

.score-item-text__textarea {
  width: 100%;
  resize: vertical;
}

.score-item-text__text-readonly {
  border: 1px solid black;
  background-color: white;
  padding: 10px;
}

.score-item-text__text-readonly--light {
  border-color: colors.$color-black-border;
  color: color.scale(colors.$color-black-border, $lightness: -40%);
}

.score-item-text__label {
  font-size: 12px;
  margin-bottom: 2px;

  padding-left: 2px;
}
</style>
