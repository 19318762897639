<template>
  <div class="components__cnt-image">
    <div
      class="single-image-container"
      v-for="file in contentItem.files"
      v-bind:key="file.id"
    >
      <img v-bind:src="file.url" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.components__cnt-image {
  display: flex;
  justify-content: center;

  img {
    max-width: 100%;
  }
}
</style>

<script>
import CntComponent from "../../mixins/cnt-component";

export default {
  mixins: [CntComponent],
};
</script>
