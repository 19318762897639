<template>
  <div class="components__rec-component-collection">
    <div
      v-bind:class="{ distanced: description.distanced }"
      v-for="component in description.components"
      v-bind:key="component.id"
      class="single-component"
    >
      <component
        v-bind:is="component.component"
        v-bind:description="component"
        v-bind:class="{ 'print--hide': component.print_hide }"
        v-on:submit="$emit('submit')"
        v-on:submit-as-draft="$emit('submit-as-draft')"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.components__rec-component-collection {
  .single-component {
    &.distanced {
      margin-bottom: 20px;
    }
  }
}
</style>

<script>
import RecComponent from "../../mixins/rec-component";

export default {
  mixins: [RecComponent],
};
</script>
