<script>
import ThemingMixin from "vue/applications/frontend/mixins/theming-mixin";

export default {
  mixins: [ThemingMixin],
  props: {
    infoItem: {
      type: Object,
    },
  },
};
</script>

<template>
  <div class="components__info-item">
    <div v-if="infoItem.description_html">
      <span v-html="infoItem.description_html"></span>
    </div>
    <div v-if="infoItem.info_html">
      <a
        v-bind:class="[
          { 'item--disabled': infoItem.info_html.style_disabled },
          themingCssClass('normal'),
        ]"
        target="_blank"
        v-bind:href="infoItem.info_html.target"
        >{{ infoItem.info_html.label }}</a
      >
    </div>
    <div v-if="infoItem.mediapool_item">
      <a v-bind:href="infoItem.mediapool_item.file_url" target="_blank">{{
        infoItem.mediapool_item.title
      }}</a>
    </div>
  </div>
</template>

<style lang="scss">
.components__info-item {
  p,
  ul,
  ol,
  li {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 10px;
  }

  p {
    margin: 0;
  }
}
</style>

<style lang="scss">
@use "~stylesheets/frontend/variables/colors";

.components__info-item {
  a,
  a:hover,
  a:visited {
    font-size: 16px;

    &.item--disabled {
      color: darken(colors.$color-black-inactive, 10%);
    }
  }
}
</style>
