<template>
  <div class="components__logo-paedcompenda">
    <router-link to="/">
      <template v-if="inverted">
        <img src="../../../../images/logo_paedcompenda_inverted.png" />
      </template>
      <template v-else>
        <img src="../../../../images/logo_paedcompenda.png" />
      </template>
    </router-link>
  </div>
</template>

<style lang="scss" scoped>
.components__logo-paedcompenda {
  width: 221px;

  img {
    width: 100%;
  }
}
</style>

<script>
export default {
  props: {
    inverted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
};
</script>
