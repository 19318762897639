<template>
  <div class="components__rec-plain-text">{{description.content}}</div>
</template>

<script>
import RecComponent from "../../mixins/rec-component";

export default {
  mixins: [RecComponent],
};
</script>
