<template>
  <div class="components__rec-accordion">
    <div class="accordion-top" v-bind:class="expandedClass" v-on:click="toggle">
      <div class="accordion-title" v-bind:class="[themingCssClass('normal')]">
        {{ description.accordion_title }}
      </div>
      <div class="accordion-chevron" v-bind:class="[themingCssClass('normal')]">
        <i class="frontend-icon" v-bind:class="expandIconClass"></i>
      </div>
    </div>
    <div
      v-if="
        expanded &&
        description &&
        description.content &&
        description.content.component
      "
      class="accordion-content"
    >
      <component
        v-bind:is="description.content.component"
        v-bind:description="description.content"
        v-on:submit="$emit('submit')"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.components__rec-accordion {
  .accordion-top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;

    &.expanded {
      padding-bottom: 20px;
    }

    .accordion-chevron {
      font-size: 30px;
      height: 15px;

      > i {
        line-height: 15px;
      }
    }

    .accordion-title {
      font-size: 18px;
    }
  }
}
</style>

<script>
import RecComponent from "../../mixins/rec-component";

export default {
  mixins: [RecComponent],
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    expandIconClass() {
      if (this.expanded) {
        return "fi-expand_less";
      } else {
        return "fi-expand_more";
      }
    },
    expandedClass() {
      if (this.expanded) {
        return "expanded";
      } else {
        return "retrieved";
      }
    },
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },
  },
};
</script>
