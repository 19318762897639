<script>
import CntComponent from "../../mixins/cnt-component";
import ThemingMixin from "vue/applications/frontend/mixins/theming-mixin";

export default {
  mixins: [CntComponent, ThemingMixin],
  computed: {
    saneEntries() {
      return (this.contentItem.widget_data.entries || []).sort(
        (a, b) => a.position - b.position,
      );
    },
    iconName() {
      return this.contentItem.widget_data.icon_name;
    },
    numberOfColumns() {
      return this.contentItem.widget_data.number_of_columns || 1;
    },
    mainStyle() {
      return {
        "grid-template-columns": `repeat(${this.numberOfColumns}, 1fr)`,
        "grid-template-rows": `repeat(${Math.ceil(this.saneEntries.length * 1.0 / this.numberOfColumns)}, auto)`,
      };
    },
    title() {
      return this.contentItem.widget_data.title;
    },
    subtitle() {
      return this.contentItem.widget_data.subtitle;
    },
  },
};
</script>

<template>
  <div class="components__cnt-list">
    <h1 v-if="title" class="cnt-list__title" v-bind:class="[themingCssClasses(['normal'])]">{{ title }}</h1>
    <div class="cnt-list__subtitle" v-bind:class="[themingCssClasses(['normal'])]">{{ subtitle }}</div>
    <div class="list" v-bind:style="mainStyle">
      <div
        v-for="entry in saneEntries"
        v-bind:key="entry.id"
        class="list__entry"
      >
        <i
          v-if="iconName"
          class="list__entry__icon"
          v-bind:class="[iconName, themingCssClasses(['normal'])]"
        ></i>
        <div>{{ entry.text }}</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "~stylesheets/frontend/variables/colors";
@import "~stylesheets/frontend/variables/font-families";
@import "~include-media/dist/include-media";

$breakpoints: (
  "phone": 400px,
  "phone2": 500px,
  "tablet": 600px,
  "tablet2": 720px,
  "desktop": 1078px,
  "large": 1260px,
);

$height_breakpoint: 800px;
$reduced_height_padding: 35px;

.components__cnt-list {
  margin-left: 60px;
  margin-right: 60px;
}

.list {
  display: grid;
  grid-gap: 30px 54px;
  grid-auto-flow: column;

  @include media("<desktop") {
    display: block;
  }
}

.list__entry {
  line-height: 28px;
  font-size: 18px;
  color: $color-black;

  display: flex;

  margin-bottom: 30px;

  > i {
    margin-right: 19px;
    font-size: 44px;
  }
}

.cnt-list__title {
  font-size: 45px;
  font-family: $font-family_project-title;
  font-weight: $font-weight_project-title;
  margin-bottom: 5px;
  margin-top: 0;

  @include media("<tablet") {
    font-size: 10vw;
    margin-bottom: 5px;
  }
}

.cnt-list__subtitle {
  font-size: 22px;
  margin-bottom: 66px;

  @include media("<desktop") {
    margin-bottom: 20px;
  }

  @include media("<tablet") {
    font-size: 5vw;
  }
}

// specificality 0 to not overwrite / interfere theming
:where(.cnt-list__subtitle) {
  color: $color-green;
}

:where(.list__entry__icon) {
  color: $color-green;
}
</style>
