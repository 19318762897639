
<script>
import { parseISO, add, format } from "date-fns";

import CntComponent from "../../mixins/cnt-component";
import UniversalEndpoint from "../../endpoints/universal-endpoint";
import HttpMethod from "vue/applications/shared/endpoints/utils/http-method";

export default {
  mixins: [CntComponent],
  data: () => {
    return {
      backendData: {
        selectedPractice: null,
        selectedCurriculumId: null,
        selectedLicenseClass: null,
        selectedStartAt: null,
        selectedAmount: null,
        comment: "",
        trainee_email: null,
        trainee_first_name: null,
        trainee_last_name: null,
      },
      sending: false,
      errors: [],
      role_type: null,
    };
  },
  computed: {
    availableLicensesForCurriculum: function () {
      if(!this.backendData.selectedCurriculumId) return this.contentItem.widget_data.available_licenses;

      return this.contentItem.widget_data.available_licenses.filter(license => {
        return license.curriculum_id == this.backendData.selectedCurriculumId
      });
    },

    selectedRole: function() {
      if (
        !this.contentItem ||
        !this.contentItem.widget_data ||
        !this.contentItem.widget_data.roles
      ) return null;

      return this.contentItem.widget_data.roles.find(role => role.id === this.role_type)
    },

    selectedLicense: function () {
      if (
        !this.contentItem ||
        !this.contentItem.widget_data ||
        !this.contentItem.widget_data.available_licenses
      )
        return null;

      for (const license of this.contentItem.widget_data.available_licenses) {
        if (license.license_class === this.backendData.selectedLicenseClass) {
          return license;
        }
      }
      return null;
    },

    selectedCurriculum: function() {
      if (
        !this.contentItem ||
        !this.contentItem.widget_data ||
        !this.contentItem.widget_data.available_curricula
      )
        return null;

      for (const curriculum of this.contentItem.widget_data.available_curricula) {
        if (curriculum.id === this.backendData.selectedCurriculumId) {
          return curriculum;
        }
      }
      return null;
    },

    needSelectStartDate: function () {
      return true;
    },

    showSelectStartDate: function () {
      return this.needSelectStartDate && this.selectedLicense;
    },

    needSelectAmount: function () {
      return this.selectedLicense?.booking_options?.length > 1;
    },

    showSelectAmount: function () {
      return (
        this.needSelectAmount &&
        this.selectedLicense &&
        (!this.needSelectStartDate || this.backendData.selectedStartAt)
      );
    },

    effectiveSelectedAmount: function () {
      if (this.backendData.selectedAmount) {
        for (const bookingOption of this.selectedLicense.booking_options) {
          if (bookingOption.id === this.backendData.selectedAmount) {
            return bookingOption;
          }
        }
        console.error("No booking option found. This should not happen");
        return null;
      } else if (this.selectedLicense.booking_options.length == 1) {
        return this.selectedLicense.booking_options[0];
      } else {
        return null;
      }
    },

    showConfirmation: function () {
      return (
        this.selectedLicense &&
        this.backendData.trainee_email &&
        this.backendData.trainee_first_name &&
        this.backendData.trainee_last_name &&
        (!this.needSelectStartDate || this.backendData.selectedStartAt) &&
        (!this.needSelectAmount || this.backendData.selectedAmount)
      );
    },

    // returns the end date, null indicates no end date (i.e. unlimited period of validity)
    endDate: function () {
      if (
        !this.backendData.selectedStartAt ||
        !this.selectedLicense ||
        !this.effectiveSelectedAmount
      )
        return null;

      if (this.effectiveSelectedAmount.duration.unit == "unlimited") {
        return null;
      }

      const adder = {};
      adder[
        this.effectiveSelectedAmount.duration.unit
      ] = this.effectiveSelectedAmount.duration.amount;
      return add(parseISO(this.backendData.selectedStartAt), adder);
    },
  },
  methods: {
    translateDuration: function (duration, amount) {
      if (!amount) amount = 100;
      switch (duration) {
        case "years":
          return amount > 1 ? "Jahre" : "Jahr";
        case "months":
          return amount > 1 ? "Monate" : "Monat";
        case "weeks":
          return amount > 1 ? "Wochen" : "Woche";
        default:
          return duration;
      }
    },
    formatISODate: function (dateStr) {
      if (!dateStr) return "unbekannt";
      return this.formatDate(parseISO(dateStr));
    },
    formatDate: function (date) {
      if (!date) return "unbekannt";
      return format(date, "dd.MM.yyyy");
    },
    formatPrice: function (price) {
      if (price !== 0 && !price) return "-";

      const cents = price % 100;
      const paddedCents = `${cents}00`.substring(0, 2);

      const euros = (price - cents) / 100;

      return `${euros},${paddedCents}`;
    },

    onBookLicense: async function () {
      if (this.sending) return;

      let skipSendingFlip = false;

      try {
        this.sending = true;
        this.errors = [];

        const endpoint = new UniversalEndpoint(this.$store);
        const response = await endpoint.request(
          this.contentItem.widget_data.license_booking_user_path,
          HttpMethod.POST,
          this.backendData,
        );

        if (response && response.data) {
          if (response.data.redirect) {
            location.href = response.data.redirect;
            skipSendingFlip = true;
          } else {
            const errMessage = response.data.error ?? "Es ist etwas schief gelaufen. Bitte versuchen Sie es erneut. Falls der Fehler wiederholt auftritt, wenden Sie sich bitte an it-support@paedcompenda.de";
            this.errors.push(errMessage);
          }
        }
      } finally {
        this.sending = skipSendingFlip;
      }
    },
  },
  watch: {
    selectedLicense: function () {
      this.backendData.selectedAmount = null;
    },
    "backendData.selectedCurriculumId": function () {
      this.backendData.selectedLicenseClass = null;
    },
    "contentItem.widget_data.available_curricula": {
      immediate: true,
      handler: function () {
        if(this.contentItem.widget_data.available_curricula.length == 1) {
          this.backendData.selectedCurriculumId = this.contentItem.widget_data.available_curricula[0].id
        }
      }
    }
  },
};
</script>

<template>
  <div class="components__cnt-license">
    <template v-if="contentItem">
      <div
        class="license__not-logged-in"
        v-if="contentItem.widget_data.login_path"
      >
        Sie müssen sich zunächst
        <a v-bind:href="contentItem.widget_data.login_path"
          >einloggen oder registrieren</a
        >, um die Bestellung einer Lizenz durchführen zu können.
      </div>
      <div v-else>
        <div>
          <label class="license__option">
            <select v-model="role_type">
              <option v-bind:value="null">Bitte auswählen:</option>
              <option
                v-for="role in contentItem.widget_data.roles"
                v-bind:key="role.id"
                v-bind:value="role.id"
              >
                {{ role.label }}
              </option>
            </select>
            <div>
              <strong>
                <template v-if="role_type">{{ selectedRole.label }}</template>
                <template v-else>Ich möchte PaedCompenda nutzen als</template>
              </strong>
            </div>
          </label>
        </div>

        <template v-if="role_type == 'trainee'">
          <p>Die Lizenzbestellung wird von Ihrer/Ihrem Weiterbildungsberechtigten vorgenommen. Bis zur Genehmigung der Lizenz können Sie kostenfrei die Schnupperversion verwenden.</p>
        </template>
        <template v-else-if="role_type != null">
          <div>
            <label class="license__option">
              <template v-if="contentItem.widget_data.available_practices.length > 0">
                <select v-model="backendData.selectedPractice">
                  <option v-bind:value="null" disabled>Bitte auswählen</option>
                  <option
                    v-for="practice in contentItem.widget_data.available_practices"
                    v-bind:key="practice"
                    v-bind:value="practice"
                  >
                    {{ practice }}
                  </option>
                </select>
                <div>
                  <strong>
                    <template v-if="backendData.selectedPractice">{{ backendData.selectedPractice }}</template>
                    <template v-else>Aus- und Weiterbildungsstätte</template>
                  </strong>
                </div>
              </template>
              <template v-else>
                <div>
                  <strong>Aus- und Weiterbildungsstätte</strong>
                  <div><a v-bind:href="this.contentItem.widget_data.practice_data_path">Bitte geben Sie hier zunächst die Daten Ihrer Aus- und Weiterbildungsstätte an.</a></div>
                </div>
              </template>
            </label>
          </div>

          <template v-if="backendData.selectedPractice">
            <div>
              <label class="license__option">
                <template v-if="contentItem.widget_data.available_curricula.length > 1">
                  <select v-model="backendData.selectedCurriculumId">
                    <option v-bind:value="null">Bitte auswählen:</option>
                    <option v-for="curriculum in contentItem.widget_data.available_curricula" v-bind:key="curriculum.id" v-bind:value="curriculum.id">
                      {{ curriculum.name }}
                    </option>
                  </select>
                </template>
                <div>
                  <strong>
                    <template v-if="selectedCurriculum">{{selectedCurriculum.name}}</template>
                    <template v-else>Curriculum</template>
                  </strong>
                  <div v-if="selectedCurriculum && selectedCurriculum.short_description">{{ selectedCurriculum.short_description }}</div>
                </div>
              </label>
            </div>
            <template v-if="backendData.selectedCurriculumId">
              <p v-if="availableLicensesForCurriculum.length <= 0">
                Aktuell steht keine Lizenz für das Curriculum zur Verfügung.
              </p>
              <div
                v-for="license in availableLicensesForCurriculum"
                v-bind:key="license.license_class"
              >
                <label
                  class="license__option"
                  v-bind:class="{
                    'license__option--disabled': license.disabled,
                    'license__option--selected': license === selectedLicense,
                  }"
                >
                  <input
                    type="radio"
                    v-bind:disabled="license.disabled || sending"
                    v-bind:value="license.license_class"
                    v-model="backendData.selectedLicenseClass"
                  />
                  <div>
                    <strong>{{ license.title }}</strong>
                    <div v-if="license.description">{{ license.description }}</div>
                    <div v-if="license.disabled">
                      <i>nicht verfügbar</i>
                    </div>
                  </div>
                </label>
              </div>

              <div
                v-if="this.selectedLicense"
                class="license__input-container"
              >
                <label>
                  <strong>Für welche Ärztin in Weiterbildung / welchen MFA-Azubi soll die Lizenz verwendet werden?</strong>
                  <div class="trainee-field">
                      <label for="trainee_email">E-Mail-Adresse der ÄiW / des MFA-Azubi</label>
                      <input type="email" id="trainee_email" v-model="backendData.trainee_email" placeholder="E-Mail-Adresse" required>
                  </div>
                  <div class="trainee-field">
                    <label for="trainee_first_name">Vorname der ÄiW / des MFA-Azubi</label>
                    <input type="text" id="trainee_first_name" v-model="backendData.trainee_first_name" placeholder="Vorname" required>
                  </div>
                  <div class="trainee-field">
                    <label for="trainee_last_name">Nachname der ÄiW / des MFA-Azubi</label>
                    <input type="text" id="trainee_last_name" v-model="backendData.trainee_last_name" placeholder="Nachname" required>
                  </div>
                </label>
              </div>

              <div
                v-if="showSelectStartDate"
                class="license__select-start-date license__input-container"
              >
                <label
                  ><strong
                    >Wählen Sie den gewünschten Startzeitpunkt der Lizenz aus</strong
                  >
                  <div class="license__input-wrapper">
                    <simple-datepicker
                      future
                      v-bind:min-date="new Date()"
                      v-model="backendData.selectedStartAt"
                      skip-default-value
                      v-bind:disabled="sending"
                    ></simple-datepicker>
                  </div>
                </label>
              </div>

              <div
                v-if="showSelectAmount"
                class="license__select-amount license__input-container"
              >
                <label
                  ><strong>Wählen Sie die gewünschte Laufzeit der Lizenz aus</strong>
                  <div class="license__input-wrapper">
                    <select
                      v-bind:disabled="sending"
                      v-model="backendData.selectedAmount"
                    >
                      <option
                        v-for="bookingOption in selectedLicense.booking_options"
                        v-bind:key="bookingOption.id"
                        v-bind:value="bookingOption.id"
                      >
                        {{ bookingOption.duration.amount }}
                        {{
                          translateDuration(
                            bookingOption.duration.unit,
                            bookingOption.duration.amount,
                          )
                        }}
                      </option>
                    </select>
                  </div>
                </label>
              </div>

              <div class="license__confirmation" v-if="showConfirmation">
                <h2>Zusammenfassung</h2>
                <div
                  class="license__input-container license__input-container--no-top-margin license__input-container--with-bottom-margin"
                >
                  <label>
                    <strong>Anmerkungen zur Buchung</strong>
                    <div class="license__input-wrapper">
                      <textarea
                        rows="5"
                        v-bind:disabled="sending"
                        placeholder="Anmerkungen..."
                        v-model="backendData.comment"
                      ></textarea>
                    </div>
                  </label>
                </div>
                <p>
                  Ich möchte die Option
                  <strong>&#132;{{ selectedLicense.title }}&#147;</strong> ab dem
                  <strong>{{ formatISODate(backendData.selectedStartAt) }}</strong>
                  <template v-if="endDate">
                    bis zum <strong>{{ formatDate(endDate) }}</strong>
                  </template>
                  <template v-if="effectiveSelectedAmount.price > 0">
                    für einmalig
                    <strong
                      >{{ formatPrice(effectiveSelectedAmount.price) }} &euro;</strong
                    >
                    (inkl. 19% MwSt.) kostenpflichtig</template
                  ><template v-else>kostenlos</template> bestellen.
                </p>
                <div v-if="errors">
                  <div
                    v-for="(error, index) in errors"
                    v-bind:key="index"
                    class="booking__error"
                  >
                    {{ error }}
                  </div>
                </div>
                <div>
                  <button
                    class="button"
                    v-bind:class="{ 'button--disabled': sending }"
                    v-on:click="onBookLicense"
                  >
                    <template v-if="sending">
                      Bestellung wird durchgeführt... </template
                    ><template v-else>
                      <template v-if="effectiveSelectedAmount.price > 0"
                        >Kostenpflichtig</template
                      >
                      bestellen</template
                    >
                  </button>
                </div>
              </div>
            </template>
          </template>
        </template>
      </div>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.components__cnt-license {
  @import "~stylesheets/frontend/variables/colors";

  .license__not-logged-in {
    padding: 20px;
    background-color: $color-black-very-light;
  }

  .license__option {
    display: block;
    padding: 20px;
    margin: 20px 0;
    border: 1px solid $color-green;

    display: flex;
    align-items: center;

    input, select {
      margin-right: 20px;
    }
  }

  .license__option--selected {
    background-color: $color-green;
    color: $color-inverted;
  }

  .license__option--disabled {
    background-color: $color-black-vertical-line;
    border: 1px solid $color-black-vertical-line;
  }

  .license__input-container {
    margin-top: 40px;
  }

  .license__input-wrapper {
    margin-top: 5px;

    textarea {
      width: 100%;
    }
  }

  .license__input-container--no-top-margin {
    margin-top: 0;
  }

  .license__input-container--with-bottom-margin {
    margin-bottom: 20px;
  }

  .license__select-amount {
    select {
      padding: 1px 2px;
    }
  }

  .license__confirmation {
    margin-top: 40px;
  }

  .booking__error {
    margin-bottom: 15px;
    color: red;
    font-weight: bold;
  }

  .trainee-field {
    margin-top: 10px;

    label {
      display: inline-block;
      width: 300px;
    }

    input {
      display: inline-block;
      width: 300px;
    }
  }

  .button {
    background-color: $color-green;
    color: $color-inverted;

    padding: 10px 20px;
    cursor: pointer;
    box-shadow: none;
    outline: none;
    border: none;
  }

  .button--disabled {
    background-color: $color-black-inactive;
    cursor: not-allowed;
  }
}
</style>
