import { Module } from "vuex";

import RootState from "../../root-state";
import State from "./state";

import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const FilterModule: Module<State, RootState> = {
  actions,
  getters,
  mutations,
  state: new State(),
};
export default FilterModule;
