<template>
  <div class="components__rec-additional-info">
    <ul>
      <li v-for="infoItem in description.info_items" v-bind:key="infoItem.id">
        <info-item v-bind:info-item="infoItem"></info-item>
      </li>
    </ul>
  </div>
</template>

<script>
import RecComponent from "../../mixins/rec-component";

export default {
  mixins: [RecComponent],
};
</script>
