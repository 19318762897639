<script>
import SyncedSlots from "../../mixins/synced-slots";

export default {
  mixins: [
    SyncedSlots,
  ],
};
</script>

<template>
  <div class="layout layout--stacked">
    <div><slot name="label"></slot></div>
    <div><slot name="user-input"></slot></div>
    <div><slot name="keywords"></slot></div>
    <div class="print--hide"><slot name="previous-input"></slot></div>
    <div v-for="syncedSlot in syncedSlotNames" class="print--hide"><slot v-bind:name=syncedSlot></slot></div>
    <div><slot name="more-button"></slot></div>
  </div>
</template>
