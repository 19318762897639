import ThemingMixin from "vue/applications/frontend/mixins/theming-mixin";

export default {
  mixins: [ThemingMixin],
  props: {
    description: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
